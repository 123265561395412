import React, { useState, useEffect } from "react";
import Countdown from 'react-countdown';



async function fetchUpcomingLPBProjects() {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/upcoming_lpbprojects`);
    
    if (response.status === 404) {
        return null;
    }
    const data = await response.json();

    if (Object.keys(data).length === 0) {
        // data is an empty object
        return null;
    }
    return data;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Peut afficher un message de fin ou renvoyer un autre composant ici
      return <span>La collecte a déjà commencé !</span>;
    } else {
      // Renvoie le temps restant
      return <span>{days}j {hours}h {minutes}m {seconds}s</span>;
    }
  };


function UpcomingLPBProjects() {
  const [project, setProject] = useState(null);


  useEffect(() => {
    fetchUpcomingLPBProjects().then(data => setProject(data));
  }, []);


  return (
    <div className="right-sidebar">
      {project && (
        <div className="promoLBP">
          <div className="promoLBP-titre">Projet à venir</div>
          <div className="promoLBP-compteur"><Countdown date={new Date(project.date)} renderer={renderer} /></div>
          <div className="promoLBP-nom">{project.name}</div>
          <div className="promoLBP-montant">{parseInt(project.amount).toLocaleString()} €</div>
          <div className="promoLBP-taux">{parseFloat(project.rate).toFixed(1)} %  -  {project.duration} mois</div>
          
          <div className="promoLBP-cover">
            <img src={project.image_url} />
          </div>          
          <div className="promoLBP-titre-parrainage">BONUS<br/>1% de rendement supplémentaire !</div>
            <div className="promoLBP-parrainage">Si vous n'êtes pas déjà inscrit à La Première Brique, bénéficiez de 1% de rendement supplémentaire sur votre premier investissement en utilisant <a href="https://www.lapremierebrique.fr/fr/users/sign_up/OPGJET" target="_blank">ce lien d'affiliation</a> pour vous inscrire</div>
            <div className="promoLBP-bouton-parrainage"><a href="https://www.lapremierebrique.fr/fr/users/sign_up/OPGJET" target="_blank">S'INSCRIRE</a></div>
        </div>
      )}
    </div>
  );
}

export default UpcomingLPBProjects;