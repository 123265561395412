//import { useAuth } from "./App";
import React, { useEffect, useState, useRef } from 'react';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
//import Chart from 'chart.js/auto';
import { Chart } from 'chart.js';
import UpcomingLPBProject from "./UpcomingLPBProjects";
//import CountdownDisplay from "./CountdownDisplay";


function StatsPlatforms() {
    const [lpbProjects, setLpbProjects] = useState([]);
    const [timeInterval, setTimeInterval] = useState('month');


    // Graphe avec montants collectés cumulés
    const canvasRef1 = useRef(null);
    const chartRef1 = useRef(null);

    // Graphe avec montants collectés moyen par projet et par mois
    const canvasRef2 = useRef(null);
    const chartRef2 = useRef(null);

    // Graphe avec nombre de projets financés par mois
    const canvasRef3 = useRef(null);
    const chartRef3 = useRef(null);    

    // Graphe avec montant moyen investit par investisseur
    const canvasRef4 = useRef(null);
    const chartRef4 = useRef(null);    

    // Graphe avec taux moyen par mois
    const canvasRef5 = useRef(null);
    const chartRef5 = useRef(null);    

    // Barre chart avec nombre de projets par département
    const canvasRef6 = useRef(null);
    const chartRef6 = useRef(null);        

    // Barre chart avec nombre de projets de chaque type 
    const canvasRef7 = useRef(null);
    const chartRef7 = useRef(null);        
    
    // Donuts chart avec répartitions des projets par type
    const canvasRef8 = useRef(null);
    const chartRef8 = useRef(null);      
    
    // Graphe 9 - Répartition par régions françaises
    const canvasRef9 = useRef(null);
    const chartRef9 = useRef(null);      
    
    
    function useCountdown(targetDate) {
      const [countdown, setCountdown] = useState('');
    
      useEffect(() => {
        let interval;
        const updateCountdown = () => {
          const currentTime = new Date();
          const timeDifference = targetDate - currentTime;
          
          if (timeDifference <= 0) {
            setCountdown("0j 0h 0m 0s");
            
            clearInterval(interval);
            return;
          }
    
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    
          setCountdown(`${days}j ${hours}h ${minutes}m ${seconds}s`);
        };
    
        updateCountdown();
        interval = setInterval(updateCountdown, 1000);
    
        return () => clearInterval(interval); // Nettoyez l'intervalle lors du démontage du composant
      }, [targetDate]);
    
      return countdown;
    }

    function CountdownDisplay(props) {
      const targetDate = new Date(props.startDate);
      const countdown = useCountdown(targetDate);    
      return (
        <div>
          {countdown}
        </div>
      );
    }
    
  
    // Liste des régions et de leur départements
    
    const regionsDepartements = {
      "Auvergne-Rhône-Alpes": [
        { "num": "1", "nom": "Ain" },
        { "num": "3", "nom": "Allier" },
        { "num": "7", "nom": "Ardèche" },
        { "num": "15", "nom": "Cantal" },
        { "num": "26", "nom": "Drôme" },
        { "num": "38", "nom": "Isère" },
        { "num": "42", "nom": "Loire" },
        { "num": "43", "nom": "Haute-Loire" },
        { "num": "63", "nom": "Puy-de-Dôme" },
        { "num": "69", "nom": "Rhône" },
        { "num": "73", "nom": "Savoie" },
        { "num": "74", "nom": "Haute-Savoie" }
      ],
      "Bourgogne-Franche-Comté": [
        { "num": "21", "nom": "Côte-d'Or" },
        { "num": "25", "nom": "Doubs" },
        { "num": "39", "nom": "Jura" },
        { "num": "58", "nom": "Nièvre" },
        { "num": "70", "nom": "Haute-Saône" },
        { "num": "71", "nom": "Saône-et-Loire" },
        { "num": "89", "nom": "Yonne" },
        { "num": "90", "nom": "Territoire de Belfort" }
      ],
      "Bretagne": [
        { "num": "22", "nom": "Côtes-d'Armor" },
        { "num": "29", "nom": "Finistère" },
        { "num": "35", "nom": "Ille-et-Vilaine" },
        { "num": "56", "nom": "Morbihan" }
      ],
      "Centre-Val de Loire": [
        { "num": "18", "nom": "Cher" },
        { "num": "28", "nom": "Eure-et-Loir" },
        { "num": "36", "nom": "Indre" },
        { "num": "37", "nom": "Indre-et-Loire" },
        { "num": "41", "nom": "Loir-et-Cher" },
        { "num": "45", "nom": "Loiret" }
      ],
      "Corse": [
        { "num": "2A", "nom": "Corse-du-Sud" },
        { "num": "2B", "nom": "Haute-Corse" }
      ],
      "Grand Est": [
      { "num": "8", "nom": "Ardennes" },
      { "num": "10", "nom": "Aube" },
      { "num": "51", "nom": "Marne" },
      { "num": "52", "nom": "Haute-Marne" },
      { "num": "54", "nom": "Meurthe-et-Moselle" },
      { "num": "55", "nom": "Meuse" },
      { "num": "57", "nom": "Moselle" },
      { "num": "67", "nom": "Bas-Rhin" },
      { "num": "68", "nom": "Haut-Rhin" },
      { "num": "88", "nom": "Vosges" }
      ],
      "Hauts-de-France": [
      { "num": "02", "nom": "Aisne" },
      { "num": "59", "nom": "Nord" },
      { "num": "60", "nom": "Oise" },
      { "num": "62", "nom": "Pas-de-Calais" },
      { "num": "80", "nom": "Somme" }
      ],
      "Île-de-France": [
      { "num": "75", "nom": "Paris" },
      { "num": "77", "nom": "Seine-et-Marne" },
      { "num": "78", "nom": "Yvelines" },
      { "num": "91", "nom": "Essonne" },
      { "num": "92", "nom": "Hauts-de-Seine" },
      { "num": "93", "nom": "Seine-Saint-Denis" },
      { "num": "94", "nom": "Val-de-Marne" },
      { "num": "95", "nom": "Val-d'Oise" }
      ],
      "Normandie": [
      { "num": "14", "nom": "Calvados" },
      { "num": "27", "nom": "Eure" },
      { "num": "50", "nom": "Manche" },
      { "num": "61", "nom": "Orne" },
      { "num": "76", "nom": "Seine-Maritime" }
      ],
      "Nouvelle-Aquitaine": [
      { "num": "16", "nom": "Charente" },
      { "num": "17", "nom": "Charente-Maritime" },
      { "num": "19", "nom": "Corrèze" },
      { "num": "23", "nom": "Creuse" },
      { "num": "24", "nom": "Dordogne" },
      { "num": "33", "nom": "Gironde" },
      { "num": "40", "nom": "Landes" },
      { "num": "47", "nom": "Lot-et-Garonne" },
      { "num": "64", "nom": "Pyrénées-Atlantiques" },
      { "num": "79", "nom": "Deux-Sèvres" },
      { "num": "86", "nom": "Vienne" },
      { "num": "87", "nom": "Haute-Vienne" }
      ],
      "Occitanie": [
      { "num": "9", "nom": "Ariège" },
      { "num": "11", "nom": "Aude" },
      { "num": "12", "nom": "Aveyron" },
      { "num": "30", "nom": "Gard" },
      { "num": "31", "nom": "Haute-Garonne" },
      { "num": "32", "nom":"Gers" },
      { "num": "34", "nom": "Hérault" },
      { "num": "46", "nom": "Lot" },
      { "num": "48", "nom": "Lozère" },
      { "num": "65", "nom": "Hautes-Pyrénées" },
      { "num": "66", "nom": "Pyrénées-Orientales" },
      { "num": "81", "nom": "Tarn" },
      { "num": "82", "nom": "Tarn-et-Garonne" }
      ],
      "Pays de la Loire": [
      { "num": "44", "nom": "Loire-Atlantique" },
      { "num": "49", "nom": "Maine-et-Loire" },
      { "num": "53", "nom": "Mayenne" },
      { "num": "72", "nom": "Sarthe" },
      { "num": "85", "nom": "Vendée" }
      ],
      "Provence-Alpes-Côte d'Azur": [
      { "num": "4", "nom": "Alpes-de-Haute-Provence" },
      { "num": "5", "nom": "Hautes-Alpes" },
      { "num": "6", "nom": "Alpes-Maritimes" },
      { "num": "13", "nom": "Bouches-du-Rhône" },
      { "num": "83", "nom": "Var" },
      { "num": "84", "nom": "Vaucluse" }
      ],
      "Outre-Mer": [
        { "num": "971", "nom": "Guadeloupe" },
        { "num": "972", "nom": "Martinique" },
        { "num": "973", "nom": "Guyane" },
        { "num": "974", "nom": "La Réunion" },
        { "num": "975", "nom": "Saint-Pierre-et-Miquelon" },
        { "num": "976", "nom": "Mayotte" },
        { "num": "977", "nom": "Saint-Barthélemy" },
        { "num": "978", "nom": "Saint-Martin" },
        { "num": "986", "nom": "Wallis-et-Futuna" },
        { "num": "987", "nom": "Polynésie française" },
        { "num": "988", "nom": "Nouvelle-Calédonie" },
        { "num": "989", "nom": "Île de Clipperton" }
      ]
      };

      const getDepartmentNameByNumber = (number) => {
        for (const region in regionsDepartements) {
          const department = regionsDepartements[region].find((dept) => dept.num === number);
          if (department) {
            return department.nom;
          }
        }
        return null;
      };
        
    

    
    function groupDataByInterval(data, interval) {
      const groupByMonth = interval === 'month';
      return data.reduce((acc, project) => {
        const date = new Date(project.date_collecte);
        const key = groupByMonth
          ? `${date.getFullYear()}-${date.getMonth() + 1}`
          : `${date.getFullYear()}-Q${Math.floor(date.getMonth() / 3) + 1}`;
    
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(project);
        return acc;
      }, {});
    }
    

    // Graphe 1 - Fonction de création du graphe Montant financé cumulé
    /*
    const createChart1 = (montantsCumules, months) => {
        
      if (chartRef1.current) {
        chartRef1.current.destroy();
      }
    
      const ctx = canvasRef1.current.getContext('2d');
      chartRef1.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: months.map(month => new Date(`${month}-1`).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' })),
          datasets: [{
            label: 'Montant financé cumulé',
            data: montantsCumules,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            percentagePlugin: false,
            title: {
              display: true,
              text: 'LPB - Montant financé cumulé', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: value => `${(value / 1000000).toLocaleString('fr-FR')} M€`
              }
            }
          }
          
        }
      });    
    }*/
    function createChart1(intervals, montantsCumules) {
      //console.log("montantsCumules=",montantsCumules);

      if (chartRef1.current) {
        chartRef1.current.destroy();
      }

      const ctx = canvasRef1.current.getContext("2d");
      chartRef1.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: intervals,
          datasets: [
            {
              label: "Projets financés",
              data: montantsCumules,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            percentagePlugin: false,
            title: {
              display: true,
              text: 'LPB - Montant financé cumulé', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }


    // Graphe 3 - Fonction de création du graphe avec montants collectés moyen par projet et par mois
    const createAverageChart = (data) => {
        if (chartRef3.current) {
          chartRef3.current.destroy();
        }
      
        const ctx = canvasRef3.current.getContext('2d');
        chartRef3.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.labels,
            datasets: [
              {
                label: 'Moyenne des montants collectés par mois',
                data: data.averageAmounts,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                yAxisID: 'y1', // Ajoutez un ID pour l'axe des montants moyens
              },
              {
                type: 'line', // Changez le type de 'bar' à 'line' pour le second ensemble de données
          label: 'Nombre de projets',
          data: data.numberOfProjects,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1,
          pointStyle: 'rectRounded',
          yAxisID: 'y2', // Ajoutez un ID pour l'axe du nombre de projets
              },
            ],
          },
          options: {
            plugins: {
              percentagePlugin: false,
              title: {
                display: true,
                text: 'LPB - Montant moyen financé par projet', 
                font: {
                  size: 18
                },
                padding: {
                  top: 10,
                  bottom: 30
                }                
              },
              legend: {
                position: "bottom",
                display: false,
              },
            },
            scales: {
              y1: {
                position: 'left',
                ticks: {
                  callback: (value) => `${value.toLocaleString('fr-FR')} €`,
                },
              },
              y2: {
                position: 'right',
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          },
        });
      };


    // Graphe 2 - Fonction de création du graphe avec nombre de projets financés par mois
      const createProjectsNumChart = (data) => {
       // console.log('data=',data);
        if (chartRef2.current) {
          chartRef2.current.destroy();
        }
      
        const ctx = canvasRef2.current.getContext('2d');
        chartRef2.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.labels,
            datasets: [
              {
                type: 'bar', // Changez le type de 'bar' à 'line' pour le second ensemble de données
          label: 'Nombre de projets',
          data: data.numberOfProjects,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
              },
            ],
          },
          options: {
            plugins: {
              percentagePlugin: false,
              title: {
                display: true,
                text: 'LPB - Nombre de projets financés chaque mois', 
                font: {
                  size: 18
                },
                padding: {
                  top: 10,
                  bottom: 30
                }                
              },
              legend: {
                position: "bottom",
                display: false,
              },
            },
            scales: {
              y: {
                position: 'left',
                ticks: {
                  callback: (value) => `${value.toLocaleString('fr-FR')}`,
                },
              },
              
            },
          },
        });
      };      

      
      function formatDateForPeriod(period, date) {
        if (period === 'month') {
          //console.log('période détectée : MONTH');
          return new Date(`${date}-1`).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' });
        } else if (period === 'quarter') {
          //console.log('période détectée : QUARTER');
          const year = date.split('-')[0];
          const quarter = date.split('-')[1];
          return `${quarter} ${year}`;
        }
      }

    // Graphe 4 - Fonction de création du graphe avec investissement moyen par investisseur
    const createAverageAmountByInvestorChart = (averageInvestment, timeInterval) => {
      
      if (chartRef4.current) {
        chartRef4.current.destroy();
      }
      

      const ctx = canvasRef4.current.getContext('2d');
      chartRef4.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: averageInvestment.map(data => formatDateForPeriod(timeInterval, data.key)),          
          datasets: [{
            label: 'Montant moyen investi par investisseur',
            data: averageInvestment.map(data => data.average),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            percentagePlugin: false,
            title: {
              display: true,
              text: 'LPB - Montant moyen investi par investisseur et par projet', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: value => `${Math.round(value).toLocaleString('fr-FR')} €`

              }
            }
          }
        }
      });
    };      

    // Graphe 5 - Fonction de création du graphe avec taux moyen des projets proposés 


// Code de la fonction createAverageRatesChart
const createAverageRatesChart = (intervals, averageRates, timeInterval) => {
  if (chartRef5.current) {
    chartRef5.current.destroy();
  }

  const ctx = canvasRef5.current.getContext('2d');

  chartRef5.current = new Chart(ctx, {
    type: 'line',
    data: {
      labels: intervals.map((interval) =>
        formatDateForPeriod(timeInterval, interval)
      ),
      datasets: [{
        label: 'Taux moyen des projets proposés',
        data: averageRates,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    },
    options: {
      plugins: {
        percentagePlugin: false,
        title: {
          display: true,
          text: 'LPB - Évolution du taux moyen proposé par projet',
          font: {
            size: 18
          },
          padding: {
            top: 10,
            bottom: 30
          }
        },
        legend: {
          position: "bottom",
          display: false,
        },
      },
      scales: {
        y: {
          ticks: {
            callback: value => `${value.toFixed(2)} %`
          }
        }
      }
    }
  });
};

    /*const createAverageRatesChart = (averageRates, timeInterval) => {
      if (chartRef5.current) {
        chartRef5.current.destroy();
      }
    
      const ctx = canvasRef5.current.getContext('2d');
      chartRef5.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: averageRates.map(data => formatDateForPeriod(timeInterval, data.key)),
          datasets: [{
            label: 'Taux moyen des projets proposés',
            data: averageRates.map(data => data.average),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            percentagePlugin: false,
            title: {
              display: true,
              text: 'LPB - Évolution du taux moyen proposé par projet', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: value => `${value.toFixed(2)} %`
              }
            }
          }
        }
      });
    };*/
  
    // Graphe 9 - Fonction de création du graphe avec répartition du nombre de projets par département
    const createProjectsByDepartmentChart = (projectsByDepartment) => {
      if (chartRef6.current) {
        chartRef6.current.destroy();
      }
    
      const ctx = canvasRef6.current.getContext('2d');

      chartRef6.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: projectsByDepartment.map(data => getDepartmentNameByNumber(data.department)+' ('+data.department+')'),
          datasets: [{
            label: 'Nombre de projets',
            data: projectsByDepartment.map(data => data.count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          indexAxis: 'y',

          plugins: {
            percentagePlugin: false,
            datalabels: {
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: function(value, context) {
                return 'value';
              },
              align: 'center',
              anchor: 'end',
              offset: -5
            },
          
          
            title: {
              display: true,
              text: 'LPB - Répartition du nombre de projets par département', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: false,
                
              }
            },
            y: {
              beginAtZero: true,
              ticks: {
                autoSkip: false,
              }
            }
          }
        }
      });
    };

    // Graphe 7 - Fonction de création du graphe Donut pour répartition des projets par type
    const createProjectsByTypeDonutChart = (projectsByType) => {
      if (chartRef7.current) {
        chartRef7.current.destroy();
      }
    
      const ctx = canvasRef7.current.getContext('2d');

      const percentagePlugin = (percentages) => ({
        id: 'percentagePlugin',
        afterDatasetsDraw: function (chart, args, options) {
          const ctx = chart.ctx;
          chart.data.datasets.forEach(function (dataset, i) {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach(function (element, index) {
                // Draw the text in black, with the specified font
                ctx.fillStyle = 'rgb(0, 0, 0)';
                ctx.font = 'bold 12px Arial';
      
                //const data = dataset.data[index];
                const percentage = (+percentages[index]).toFixed(1);
                const dataString = `${percentage}%`;
                const position = element.tooltipPosition();
                ctx.fillText(
                  dataString,
                  position.x - ctx.measureText(dataString).width / 2,
                  position.y - 5
                );
              });
            }
          });
        },
      });
      
          
      const totalProjects = projectsByType.reduce((sum, data) => sum + data.count, 0);
      const percentages = projectsByType.map((data) => (data.count / totalProjects) * 100);

      Chart.register(percentagePlugin(percentages));
      
      chartRef7.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: projectsByType.map(data => data.type),
          datasets: [{
            data: projectsByType.map(data => data.percentage),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 205, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            percentagePlugin: true,
            title: {
              display: true,
              text: 'LPB - Répartition des projets par type d\'opération',
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              },
              legend: {
                display: false,
              }
            },
            legend: {
              position: "bottom",
              display: true,
            },
          },

        }
      });
    };
    
    // Graphe 8 - Fonction de création du graphe avec nb de projets de chaque type
    const createStackedBarChart = (projectsByPeriodAndType, periodType) => {
      if (chartRef8.current) {
        chartRef8.current.destroy();
      }
    
      function formatDateForPeriod(period, date) {
        if (period === "month") {
          return new Date(`${date}-1`).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "short",
          });
        } else if (period === "quarter") {
          const year = date.split("-")[0];
          const quarter = date.split("-")[1];
          return `T${quarter} ${year}`;
        }
      }

      // Créez un ensemble unique de périodes pour les étiquettes de l'axe des x
      const uniquePeriods = Array.from(
        new Set(projectsByPeriodAndType.map((data) => data.period))
      );

      // Triez les périodes uniques dans l'ordre chronologique
      uniquePeriods.sort((a, b) => {
        const periodA = a.split("-");
        const periodB = b.split("-");
        const yearA = parseInt(periodA[0]);
        const yearB = parseInt(periodB[0]);
        const monthOrQuarterA = parseInt(periodA[1].replace("Q", ""));
        const monthOrQuarterB = parseInt(periodB[1].replace("Q", ""));

        if (yearA !== yearB) {
          return yearA - yearB;
        } else {
          return monthOrQuarterA - monthOrQuarterB;
        }
      });
    
      const projectTypes = Array.from(
        new Set(projectsByPeriodAndType.map((data) => data.type))
      );

      const colors = [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        // ajoutez d'autres couleurs si nécessaire
      ];
      
    
      const datasets = projectTypes.map((type, index) => {
        const dataForType = uniquePeriods.map((period) => {
          const matchingEntry = projectsByPeriodAndType.find(
            (entry) => entry.period === period && entry.type === type
          );
    
          return matchingEntry ? matchingEntry.count : 0;
        });
    
        const color = colors[index % colors.length];
        const borderColor = color.replace("0.2", "1");
    
        return {
          label: type,
          data: dataForType,
          backgroundColor: color,
          borderColor: borderColor,
          borderWidth: 1,
        };
      });

    
      const ctx = canvasRef8.current.getContext("2d");
      chartRef8.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: uniquePeriods.map((period) =>
            formatDateForPeriod(periodType, period)
          ),
          datasets: datasets,
        },
        options: {
          plugins: {
            percentagePlugin: false,
            title: {
              display: true,
              text: 'LPB - Nombre de projets par période et par type', 
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }                
            },
            legend: {
              position: "bottom",
              display: true,
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      });
    };

    // Graphe 8 - Fonction de génération du graphe avec répartition par régions françaises
    const createProjectsByRegionChart = (projectsByRegion) => {
      if (chartRef9.current) {
        chartRef9.current.destroy();
      }
    
      const ctx = canvasRef9.current.getContext('2d');
    
      chartRef9.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: projectsByRegion.map(data => data.region),
          datasets: [{
            label: 'Nombre de projets',
            data: projectsByRegion.map(data => data.count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          indexAxis: 'y',
    
          plugins: {
            percentagePlugin: false,
            datalabels: {
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: function(value, context) {
                return value;
              },
              align: 'center',
              anchor: 'end',
              offset: -5
            },
    
            title: {
              display: true,
              text: 'LPB - Répartition du nombre de projets par région',
              font: {
                size: 18
              },
              padding: {
                top: 10,
                bottom: 30
              }
            },
            legend: {
              position: 'bottom',
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: false,
              }
            },
            y: {
              beginAtZero: true,
              ticks: {
                autoSkip: false,
              }
            }
          }
        }
      });
    };
    
 
    // Hook pour chargement initial des données 
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/loans/allLPBprojects`);
          const data = await response.json();  
          setLpbProjects(data);
      
        } catch (error) {
          console.error(error);
        }
      }
      
      fetchData();
      /*
          lpbProjects.forEach((project) => {
            if (project.nom == 'La Fournaise') {
              console.log('tout =', project);
              console.log(`lpb - Projet ID: ${project.id}, Taux: ${project.taux}`);
              }
            });
            */
    }, []);

    
  
  
    // Hook pour mise à jour des graphes et données lorsque liste des projets ou l'intervalle d'affichage est modifié
    useEffect(() => {

        if (lpbProjects.length === 0) {
            return;
          }
        // Get an array of dates and montant_collecte
        const filteredData = lpbProjects.filter(project => project.montant_collecte !== null);
        const sortedData = filteredData.sort((a, b) => new Date(a.date_collecte) - new Date(b.date_collecte));
        const groupedData = groupDataByInterval(sortedData, timeInterval);
        
      
        const groupedByMonth = sortedData.reduce((acc, project) => {
          const date = new Date(project.date_collecte);
          const month = `${date.getFullYear()}-${date.getMonth() + 1}`;
          if (!acc[month]) {
            acc[month] = [];
          }
          acc[month].push(project);
          return acc;
        }, {});

        // =================================================
        // Graphe 6 - Départements - Génération des données
        // =================================================
        const projectsByDepartment = lpbProjects.reduce((acc, project) => {
          const department = project.departement;
          if (!acc[department]) {
            acc[department] = 0;
          }
          acc[department]++;
          return acc;
        }, {});
      
    

        const projectsByDepartmentArray = Object.keys(projectsByDepartment).map(department => ({
          department,
          count: projectsByDepartment[department]
        }))
        .sort((a, b) => b.count - a.count); // Tri par ordre décroissant du nombre de projets


        // ================================================================================================================
        // Graphe 7 - Génération des données et appel de la fonction de création - Répartition Donut par type d'opérations
        // ================================================================================================================

        const groupProjectsByType = (projects) => {
          const grouped = projects.reduce((acc, project) => {
            acc[project.type] = (acc[project.type] || 0) + 1;
            return acc;
          }, {});
        
          const totalCount = projects.length;
          return Object.entries(grouped).map(([type, count]) => {
            const percentage = (count / totalCount) * 100;
            return { type, count, percentage };
          });
        };

        const projectsByType = groupProjectsByType(lpbProjects);
        createProjectsByTypeDonutChart(projectsByType);
        // ================================================================================================================



        // =====================================================================================================================================================
        // Graphe 8 - Génération des données et appel de la fonction de génération du graphe pour répartition nb projets par type d'opérations au cours du temps
        // ===================================================================================================================================================== 
        const getPeriodKey = (date, timeInterval) => {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
        
          if (timeInterval === 'month') {
            return `${year}-${month.toString().padStart(2, '0')}`;
          } else if (timeInterval === 'quarter') {
            const quarter = Math.floor((month - 1) / 3) + 1;
            return `${year}-Q${quarter}`;
          }
        };
        const createProjectsByPeriodAndType = (lpbProjects, timeInterval) => {
          const projectsByPeriodAndType = [];
        
          lpbProjects.forEach(project => {
            const date = new Date(project.date_collecte);
            const periodKey = getPeriodKey(date, timeInterval);
            const existingEntry = projectsByPeriodAndType.find(entry => entry.period === periodKey && entry.type === project.type);
        
            if (existingEntry) {
              existingEntry.count++;
            } else {
              projectsByPeriodAndType.push({
                period: periodKey,
                type: project.type,
                count: 1,
              });
            }
          });
        
          return projectsByPeriodAndType;
        };
        
        const projectsByPeriodAndType = createProjectsByPeriodAndType(lpbProjects, timeInterval);
        createStackedBarChart(projectsByPeriodAndType, timeInterval);
        // ===================================================================================================================================================== 

        
        // ==================================
        // Graphe 1 - Génération des données
        // ==================================
        
        const firstDate = new Date(sortedData[0].date_collecte);
        const lastDate = new Date(sortedData[sortedData.length - 1].date_collecte);
        let currentDate = firstDate;
      
        const months = [];
        while (currentDate <= lastDate) {
          const month = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
          months.push(month);
          currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
        }
     

        function generateChart1Data(sortedData, groupedByMonth, timeInterval) {
          const firstDate = new Date(sortedData[0].date_collecte);
          const lastDate = new Date(sortedData[sortedData.length - 1].date_collecte);
          let currentDate = firstDate;
        
          const intervals = [];
          while (currentDate <= lastDate) {
            if (timeInterval === 'month') {
              const month = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
              intervals.push(month);
              currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
            } else {
              const quarter = `${currentDate.getFullYear()}-Q${Math.ceil((currentDate.getMonth() + 1) / 3)}`;
              intervals.push(quarter);
              currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3);
            }
          }
        
          //const groupedData = timeInterval === 'month' ? groupedByMonth : groupByQuarter(sortedData);
          const groupedData = timeInterval === 'month' ? groupedByMonth : groupDataByInterval(sortedData);
        
          const montantsCumules = intervals.map((interval, i) => {
            const prevIntervals = intervals.slice(0, i);
            const sumForInterval = (groupedData[interval] || []).filter(project => project.statut !== "À venir").reduce((sum, project) => sum + project.montant_collecte, 0);
            const sumForPrevIntervals = prevIntervals.reduce((sum, m) => sum + (groupedData[m] || []).filter(project => project.statut !== "À venir").reduce((s, project) => s + project.montant_collecte, 0), 0);
        
            return sumForPrevIntervals + sumForInterval;
          });
        
          return { intervals, montantsCumules };
        }
        
        

        const { intervals, montantsCumules } = generateChart1Data(sortedData, groupedByMonth, timeInterval);
        createChart1(intervals, montantsCumules);
        // ====================================


        // ==================================
        // Graphe 2 - Génération des données
        // ==================================
        function generateChart2Data(sortedData, groupedByMonth, timeInterval) {
          const firstDate = new Date(sortedData[0].date_collecte);
          const lastDate = new Date(sortedData[sortedData.length - 1].date_collecte);
          let currentDate = firstDate;
        
          const intervals = [];
          while (currentDate <= lastDate) {
            if (timeInterval === 'month') {
              const month = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
              intervals.push(month);
              currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
            } else {
              const quarter = `${currentDate.getFullYear()}-Q${Math.ceil((currentDate.getMonth() + 1) / 3)}`;
              intervals.push(quarter);
              currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3);
            }
          }
        
          const groupedData = timeInterval === 'month' ? groupedByMonth : groupDataByInterval(sortedData);
        
          const numberOfProjects = intervals.map((interval) => {
            const projectsForInterval = (groupedData[interval] || []).filter(project => project.statut !== "À venir");
            return projectsForInterval.length;
          });
          return { intervals, numberOfProjects };
        }

        //const { intervals2, numberOfProjects2 } = generateChart2Data(sortedData, groupedByMonth, timeInterval);
        const generatedChart2Data = generateChart2Data(sortedData, groupedByMonth, timeInterval);
        const intervals2 = generatedChart2Data.intervals;
        const numberOfProjects2 = generatedChart2Data.numberOfProjects;
        //console.log('intervals2=',generatedChart2Data.intervals);
        //console.log('numberOfProjects2=',generatedChart2Data.numberOfProjects);
        // ==================================
        

        // ==============================================================================================
        // Graphe 3 Génération des donnés du graphe avec montants collectés moyen par projet et par mois
        // ==============================================================================================

        // Calculez les montants moyens pour chaque mois
        // Calculez les montants moyens et le nombre de projets pour chaque mois
        const averageAmounts = [];
        const numberOfProjects = [];
        months.forEach((month) => {
          //const projectsForMonth = groupedByMonth[month] || [];
          const projectsForMonth = (groupedByMonth[month] || []).filter(project => project.statut !== "À venir");
          const sumForMonth = projectsForMonth.reduce((sum, project) => sum + project.montant_collecte, 0);
          const averageForMonth = projectsForMonth.length > 0 ? sumForMonth / projectsForMonth.length : 0;
          averageAmounts.push(averageForMonth);
          numberOfProjects.push(projectsForMonth.length);
        });

      // Graphe 3 - Créez le graphique de la moyenne des montants financés et du nombre de projets avec les données calculées
      createAverageChart({
        labels: months.map((month) =>
          new Date(`${month}-1`).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' })
        ),
        averageAmounts,
        numberOfProjects,
      });
      // ======================================= Fin Graphe 3=======================================================


        // =========================================================================
        // Graphe 2 - Créer le graphique du nombre de projets financés chaque mois
        // =========================================================================
        /*
        createProjectsNumChart({
          labels: intervals2.map((interval) =>
            timeInterval === 'month'
              ? new Date(`${interval}-1`).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' })
              : interval
          ),
          numberOfProjects2, 
        });
        */


        // ======================================================================================================================
        // Graphe 4 - Calcul le montant moyen investi par investisseur pour chaque intervalle de temps (mensuel ou trimestriel) :
        const averageInvestment = Object.entries(groupedData).map(([key, projects]) => {
          const filteredProjects = projects.filter(project => project.statut !== "À venir");
          const totalInvestment = filteredProjects.reduce((sum, project) => sum + project.montant_collecte, 0);
          const numInvestors = filteredProjects.reduce((sum, project) => sum + project.investisseurs, 0);
          const average = numInvestors > 0 ? Math.round(totalInvestment / numInvestors) : 0;
          return { key, average };
        });
        // ======================================================================================================================




      // ==============================================================================================
      // Graphe 5 - taux moyen par mois - Calculer les taux moyens pour chaque période 
      function generateChart5Data(sortedData, timeInterval) {
        const firstDate = new Date(sortedData[0].date_collecte);
        const lastDate = new Date(sortedData[sortedData.length - 1].date_collecte);
        let currentDate = firstDate;
      
        const intervals = [];
        while (currentDate <= lastDate) {
          if (timeInterval === 'month') {
            const month = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
            intervals.push(month);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
          } else {
            const quarter = `${currentDate.getFullYear()}-Q${Math.ceil((currentDate.getMonth() + 1) / 3)}`;
            intervals.push(quarter);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3);
          }
        }
      
        const groupedData = groupDataByInterval(sortedData, timeInterval);
      
        const averageRates = intervals.map((interval) => {
          const projectsForInterval = (groupedData[interval] || []);
          const totalRate = projectsForInterval.reduce((sum, project) => sum + (+project.taux), 0);
          const averageRate = projectsForInterval.length > 0 ? totalRate / projectsForInterval.length : 0;
          return averageRate;
        });
      
        return { intervals, averageRates };
      }
      // ======================================= Fin génération données Graphe 5======================



      // ==========================================================================
      // Graphe 2 - Créez le graphique avec nombre de projets financés chaque mois
      // ==========================================================================

      createProjectsNumChart({
        labels: intervals2.map((interval) =>
          timeInterval === 'month'
            ? new Date(`${interval}-1`).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' })
            : interval
        ),
        numberOfProjects: numberOfProjects2,
      });        
      
      // ==========================================================================

      

      // ==========================================================================
      // Graphe 4 - Créez le graphique avec montant moyen investi par investisseur
      // ==========================================================================
      createAverageAmountByInvestorChart(averageInvestment, timeInterval);
      // ==========================================================================

      // ==============================================================
      // Graphe5 - Génération du graphique avec taux moyen par période
      // ==============================================================
      const generatedChart5Data = generateChart5Data(sortedData, timeInterval);      
      const intervals5 = generatedChart5Data.intervals;
      const averageRates5 = generatedChart5Data.averageRates;
      createAverageRatesChart(intervals5, averageRates5, timeInterval);
      // ==============================================================
          
      // ===============================================
      // Graphe 9 - Génération du graphe avec la répartition par départements
      createProjectsByDepartmentChart(projectsByDepartmentArray);
      // =========================================================

      // ================================================================================
      // Graphe 8 - Création des données et appel de la fonction de génération du graphe
      // ================================================================================
      // Ajout d'une propriété "region" à chaque projet en utilisant regionsDepartements
      const projectsWithRegion = lpbProjects.map(project => {
        const departmentNum = project.departement.toString(); // Convertir en chaîne de caractères si nécessaire
        const region = Object.keys(regionsDepartements).find(region =>
          regionsDepartements[region].some(department => department.num === departmentNum)
        );
        return { ...project, region };
      });

    // Regroupez les projets par région
    const projectsByRegion = projectsWithRegion.reduce((acc, project) => {
      const existingRegion = acc.find(item => item.region === project.region);
      if (existingRegion) {
        existingRegion.count++;
      } else {
        acc.push({ region: project.region, count: 1 });
      }
      return acc;
    }, []);
    
    // Créez le graphique graphe 9 avec les projets regroupés par région
    createProjectsByRegionChart(projectsByRegion.sort((a, b) => b.count - a.count)); 
    // ================================================================================

  }, [lpbProjects, timeInterval]);




   // Génération / récupération des données pour Grid Item Infos

    // 1. Trouver la date_collecte la plus récente
    const infoMostRecentDate = lpbProjects.reduce((latest, project) => {
      const projectDate = new Date(project.date_collecte);
      return latest > projectDate ? latest : projectDate;
    }, new Date(0));

    // 2. Calculer le nombre total de projets
    const infoTotalProjects = lpbProjects.length;

    // 3. Obtenir le nombre de projets dans chaque statut
    const infoProjectsByStatus = lpbProjects.reduce((statusCount, project) => {
      if (!statusCount[project.statut]) {
        statusCount[project.statut] = 0;
      }
      statusCount[project.statut]++;
      return statusCount;
    }, {});
    

   

    // 4bis. Calculer la somme cumulée des montants collectés dont le statut est Financé
    const infoTotalAmountsByStatus = lpbProjects.reduce((totals, project) => {
      const status = project.statut;
    
      if (totals.hasOwnProperty(status)) {
        totals[status] += project.montant_collecte;
      } else {
        totals[status] = project.montant_collecte;
      }
      return totals;
    }, {});

    if (!infoTotalAmountsByStatus['Retardé']) {
      infoTotalAmountsByStatus['Retardé'] = 0;
    }
    if (!infoTotalAmountsByStatus['Défaut']) {
      infoTotalAmountsByStatus['Défaut'] = 0;
    }


     // 4. Calculer la somme cumulée des montants collectés
     const infoTotalAmountCollected = infoTotalAmountsByStatus['Financé'] + infoTotalAmountsByStatus['Remboursé'] + infoTotalAmountsByStatus['Retardé'] + infoTotalAmountsByStatus['Défaut'];
      
    

    // 5. calculer le nombre de projets financés sur les 6 derniers mois
    const getAverageMonthlyProjectsLast6Months = (projects, periode) => {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - periode));
      const filteredProjects = projects.filter((project) => {
        const projectDate = new Date(project.date_collecte);
        return projectDate >= sixMonthsAgo && project.statut !== "À venir";
      });
     
      const projectsCount = {
        count: filteredProjects.length,
        amount: filteredProjects.reduce((total, project) => total + project.montant_collecte, 0),
        rate: (filteredProjects.reduce((total, project) => total + (+project.taux), 0) / filteredProjects.length),
      };
      //console.log('periode = ', periode, '  filteredProjects = ', filteredProjects); 
      return projectsCount ;
    };

    //console.log('data = ',getAverageMonthlyProjectsLast6Months(lpbProjects,1));

    //lpbProjects.forEach((project) => {
      //const convertedTaux = parseFloat(project.taux.replace(',', '.'));
      //console.log(`lpb - Projet ID: ${project.id}, Taux: ${project.taux}, Taux converti: ${convertedTaux}`);
    //});
    
    //const averageMonthlyProjectsLast6Months = getAverageMonthlyProjectsLast6Months(lpbProjects,6);

    // 6. nombre de projets dont le statut est "financé" et dont la date de collecte + durée est inférieure à la date du jour
    const getFundedProjectsBeforeToday = (projects) => {
      const currentDate = new Date();
      return projects.filter((project) => {
        if (project.statut !== "financé") {
          return false;
        }

        const projectEndDate = new Date(project.date_collecte);
        projectEndDate.setDate(projectEndDate.getDate() + parseInt(project.duree_collecte));
        return projectEndDate < currentDate;
      }).length;
    };

    const fundedProjectsBeforeToday = getFundedProjectsBeforeToday(lpbProjects);
/*
    function startCountdown(targetDate) {
      const countdownElement = document.getElementById('countdown');
    
      const updateCountdown = () => {
        const currentTime = new Date();
        const timeDifference = targetDate - currentTime;
        
        if (timeDifference <= 0) {
          countdownElement.innerHTML = "0j 0h 0m 0s";
          clearInterval(interval);
          return;
        }
    
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    
        countdownElement.innerHTML = `${days}j ${hours}h ${minutes}m ${seconds}s`;
      };
    
      updateCountdown();
      const interval = setInterval(updateCountdown, 1000);
    }
    
    // Exemple d'utilisation de la fonction startCountdown:
    const targetDate = new Date("2023-05-09T12:00:00");
    //startCountdown(targetDate);
    */
     
  
    return (
      <div className="page-container">
        <div className="main-content">
        {/*<h1 className="statsPlatform">Stats "La Première Brique"<br />au {infoMostRecentDate.toLocaleDateString("fr-FR")} ({lpbProjects.length} projets)</h1>*/}
        <h1 className="statsPlatform">Stats "La Première Brique" ({lpbProjects.length} projets)</h1>

        <div className="globalInfoPlatform">

          <div className="card-big">
            <h2>
            Projets : {infoTotalProjects}
            </h2>
            <ul>
              <li>En cours : {infoProjectsByStatus['Financé']}</li>
              <li>Retards : {fundedProjectsBeforeToday}</li>
              <li>Défauts : 0</li>
              <li>Remboursés : {infoProjectsByStatus['Remboursé']}</li>
              <li>À venir : {infoProjectsByStatus['À venir']}</li>
            </ul>
          </div>
          
          <div className="card-big">
            <h2>
             Financés : {(infoTotalAmountCollected/(1000000)).toFixed(1)} M€
            </h2>
            <ul>
                <li>En cours : {(infoTotalAmountsByStatus['Financé']/1000000).toFixed(1)} M€</li>
                <li>Retards :{(infoTotalAmountsByStatus['Retardé']/1000000).toFixed(1)} M€</li>
                <li>Défauts : {(infoTotalAmountsByStatus['Défaut']/1000000).toFixed(1)} M€</li>
                <li>Remboursés : {(infoTotalAmountsByStatus['Remboursé']/1000000).toFixed(1)} M€</li>
                <li>À venir : {(infoTotalAmountsByStatus['À venir']/1000000).toFixed(1)} M€</li>
              </ul>
          </div>

          <div className="card-big">
            <h2>Taux moyen : {(getAverageMonthlyProjectsLast6Months(lpbProjects,240)['rate']).toFixed(2)} %</h2>   {/* 240 mois = 20 ans = depuis le début*/}
            <h2>Stats 6 derniers mois :</h2>
            <ul>
                <li>Projets financés : {(getAverageMonthlyProjectsLast6Months(lpbProjects,6)['count'])}</li>
                <li>Montant financé : {(getAverageMonthlyProjectsLast6Months(lpbProjects,6)['amount']/1000000).toFixed(1)} M€</li>
                <li>Taux moyen : {(getAverageMonthlyProjectsLast6Months(lpbProjects,6)['rate']).toFixed(2)} %</li>
              </ul>
              
          </div>
        </div>
    
        <div>
          <div className="sectionTitle">Stats globales</div>  
          <div className="card-buttons">
              <label>
              <input
                type="radio"
                value="month"
                checked={timeInterval === 'month'}
                onChange={(e) => setTimeInterval(e.target.value)}
              />
              Par mois
            </label>
            <label>
              <input
                type="radio"
                value="quarter"
                checked={timeInterval === 'quarter'}
                onChange={(e) => setTimeInterval(e.target.value)}
              />
              Par trimestre
            </label>
        </div>
        
       


          <div className="grid">
            <div className="grid-item">
              <div className="chart-container">
                  <canvas ref={canvasRef1} width="540" height="400"></canvas>
              </div>
            </div>


            <div className="grid-item">              
              <div className="chart-container">
                  <canvas ref={canvasRef2} width="540" height="400"></canvas>
              </div>
            </div>

            <div className="grid-item">
              <div className="chart-container">
                  <canvas ref={canvasRef3} width="540" height="400"></canvas>
              </div>
            </div>


            <div className="grid-item">              
              <div className="chart-container">
                <canvas ref={canvasRef4} width="540" height="400"></canvas>
              </div>
            </div>

            <div className="grid-item">              
              <div className="chart-container">
                <canvas ref={canvasRef5} width="540" height="600"></canvas>
              </div>
            </div>
            


          </div>

          <div className="sectionTitle">Types d'opérations</div>
            <div className="grid">


            <div className="grid-item">              
              <div className="chart-container">
                <canvas ref={canvasRef7} width="540" height="600"></canvas>
              </div>
            </div>

              <div className="grid-item">              
                <div className="chart-container">
                  <canvas ref={canvasRef8} width="540" height="600"></canvas>
                </div>
              </div>
              
            </div>
          


          <div className="sectionTitle">Répartition géographique</div>
          <div className="grid">
            
            <div className="grid-item">
              <div className="chart-container">
                <canvas ref={canvasRef9} width="540" height="600"></canvas>
              </div>
            </div>

            <div className="grid-item">              
              <div className="chart-container">
                <canvas ref={canvasRef6} width="540" height="600"></canvas>
              </div>
            </div>


          </div>      
          
        </div>
        </div>
          <UpcomingLPBProject />        
      </div>
    );
  }

export default StatsPlatforms;
