import React from 'react';
import "./AboutPage.css";


function AboutPage() {
  return (
    <div className="page-container">
      <div className="aboutPage">
        <h1>ImmoZaïk - Mai 2023</h1>
        <p>ImmoZaïk est un projet naissant et en cours de développement.</p>
        <p>L'objectif du projet est de rassembler les informations issues de vos plateformes d'investissement en Crowdfunding Immobilier.</p>
        <p>Ceci afin de vous permettre de suivre vos investissement grâce des tableaux de suivi pertinents et esthétiques.</p>  

        <h2>Qui suis-je ?</h2>
        <p>Depuis un peu plus de 2 ans, j'ai investi dans plusieurs centaines de projets en Crowdfunding Immobilier. J'ai testé de nombreuses plateformes autant en France qu'à l'étranger.</p>
        <p>C'est donc mon expérience personnelle qui m'amène aujourd'hui à développer ImmoZaïk.</p>
        
        <p>Si vous souhaitez me contacter, vous pouvez le faire sur l'adresse e-mail ci-dessous.</p>
        <p>Contact : <a href="mailto:alex@immozaik.com?subject=ImmoZaïk">alex@immozaik.com</a></p>
      </div>
    </div>
  );
}

export default AboutPage;
