
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Chart as ChartJS } from "chart.js";
import { Bar, Line, Doughnut, Chart } from "react-chartjs-2";
import { registerables } from "chart.js";
import { format } from "date-fns";
import moment from "moment";
import { useTable, useSortBy } from "react-table";
import styles from "./StatsPage.css";
import "chartjs-plugin-stacked100";

//import { GoogleSpreadsheet } from "google-spreadsheet";

import "chartjs-adapter-date-fns";
import { useAuth } from "./App";
import { getAllByText } from "@testing-library/react";

ChartJS.register(...registerables);


const StatsPage = () => {
  const [allLoans, setAllLoans] = useState([]);
  const { user } = useAuth();

  // États des dropdown du tableau
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  // Gestion de l'état d'affichage du tableau
  const [showTable, setShowTable] = useState(true);

  // Gestion de l'onglet actif
  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    const fetchAllLoans = async () => {
      if (user) {
        console.log("fetchAllLoans - user_id = ", user.id);
        try {
          //const response = await axios.get("http://localhost:3002/loans/all", {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/data/loans/all`, {
            params: { user_id: user.id },
          });
          setAllLoans(response.data);
          console.log("allLoans : ", allLoans);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des prêts en cours",
            error
          );
        }
      }
    };

    fetchAllLoans();
  }, [user]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nom du projet",
        accessor: "project_name",
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value }) => getStatusFromId(value),
      },
      {
        Header: "Plateforme",
        accessor: "platform_id",
        Cell: ({ value }) => getPlatformFromId(value),
      },

      {
        Header: "Date de collecte",
        accessor: "collect_date",
        Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
      },
      {
        Header: "Montant prêté",
        accessor: "invested_amount",
        Cell: ({ value }) =>
          value.toLocaleString("fr-FR", { minimumFractionDigits: 0 }),
      },
      {
        Header: "Taux d'intérêts (%)",
        accessor: "interests_rate",
        Cell: ({ value }) =>
          value.toLocaleString("fr-FR", { minimumFractionDigits: 0 }),
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Date remboursement prévue",
        accessor: "min_maturity_date",
        Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
      },
      {
        Header: "Date remboursement maxi prévue",
        accessor: "max_maturity_date",
        Cell: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
      },
      {
        Header: "Capital remboursé (€)",
        accessor: "principal_repaid",
        Cell: ({ value }) =>
          value.toLocaleString("fr-FR", { minimumFractionDigits: 0 }),
      },
      {
        Header: "Intérêts versés (€)",
        accessor: "interests_received",
        Cell: ({ value }) =>
          value.toLocaleString("fr-FR", { minimumFractionDigits: 0 }),
      },
    ],
    []
  );

  // Utiliser pour le filtre sur entête du tableau
  const data = React.useMemo(() => allLoans || [], [allLoans]);

  const filteredLoans = useMemo(() => {
    return allLoans.filter((loan) => {
      const matchesPlatform =
        selectedPlatform === "" ||
        getPlatformFromId(loan.platform_id) === selectedPlatform;
      const matchesStatus =
        selectedStatus === "" || String(loan.status) === selectedStatus;
      //console.log('matchesPlatform = ', matchesPlatform);

      return matchesPlatform && matchesStatus;
    });
  }, [allLoans, selectedPlatform, selectedStatus]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: filteredLoans }, useSortBy);

  // Prend en compte une modification de statut dans le dropdown de plateforme
  const handlePlatformChange = (event) => {
    setSelectedPlatform(event.target.value);
  };

  // Prend en compte une modification de statut dans le dropdown de statut
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  // Calcul les valeurs pour graphique de remboursements à venir
  const calculateMonthlyRepayments = (loans) => {
    const repayments = {};

    loans.forEach((loan) => {
      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = new Date(loan.max_maturity_date);
      const collectionDate = new Date(loan.collect_date);
      let maturityDate = new Date(loan.min_maturity_date);

      if (
        typeof maxMaturityDate !== "undefined" &&
        maxMaturityDate.length !== 0
      ) {
        // Calculer le nombre de millisecondes entre les deux dates de maturité
        const timeDiff = maxMaturityDate.getTime() - minMaturityDate.getTime();

        // Calculer la moitié du temps entre les deux dates
        const halfTimeDiff = Math.round(timeDiff / 2);

        // Modifier la date existante en ajoutant la moitié du temps à la date de début
        maturityDate.setTime(minMaturityDate.getTime() + halfTimeDiff);
      }

      // Transforme la valeur du champ maturity date du format YYYY-MM-DD à simplement YYYY-MM qui est ce qui sera utilisé pour le graphe
      const monthKey = moment(maturityDate).format("YYYY-MM");

      //const monthDiff = (minMaturityDate.getFullYear() - collectionDate.getFullYear()) * 12 + (minMaturityDate.getMonth() - collectionDate.getMonth());
      const monthDiffTerm =
        (minMaturityDate.getFullYear() - collectionDate.getFullYear()) * 12 +
        (minMaturityDate.getMonth() - collectionDate.getMonth());

      if (!repayments[monthKey]) {
        repayments[monthKey] = {
          principal_repaid: 0,
          interests_received: 0,
        };
      }

      //console.log('loan name:', loan.project_name, 'status: ', loan.status)

      if (loan.status === 5) {
        // Si le prêt est remboursé (id 5)
        repayments[monthKey].principal_repaid += loan.principal_repaid;
        repayments[monthKey].interests_received += loan.interests_received;
      } else {
        // Si le prêt est encore en cours
        // Principal = Ce qu'on a prêté - ce qui a déjà été remboursé
        // Intérets = Ce qui sera dû - ce qui déjà été versé
        repayments[monthKey].principal_repaid +=
          loan.invested_amount - loan.principal_repaid;
        repayments[monthKey].interests_received +=
          ((monthDiffTerm * loan.interests_rate) / 100 / 12) *
            loan.invested_amount -
          loan.interests_received;
      }

      //repayments[monthKey].interests_received += loan.interests_received;

      //console.log('projet: ',loan.project_name,'date:', date, 'monthKey:', monthKey, 'repayments[monthKey]',repayments[monthKey].principal_repaid);
    });

    const labels = Object.keys(repayments).sort();
    const principalData = labels.map(
      (label) => repayments[label].principal_repaid
    );
    const interestData = labels.map(
      (label) => repayments[label].interests_received
    );

    //console.log("repayments: ", repayments);

    return {
      labels,
      principalData,
      interestData,
    };
  };
  // Fin Calcul les valeurs pour graphique de remboursements à venir

  // Calcul montants actuellement investis par plateforme
  const calculateInvestedAmountBy = (loans) => {
    const investmentsByPlatform = {};
    const investmentsByStatus = {};
    const investmentsByRate = {};

    loans.forEach((loan) => {
      // Récupère le statut écrit sur la base de l'id status
      const statusLoan = getStatusFromId(loan.status);

      if (loan.status === 1) {
        // Si le prêt est en cours (id = 1)
        const platform = getPlatformFromId(loan.platform_id);

        // PLATEFORME - initialisation lorsque le tableau avec cette plateforme n'est pas encore init
        if (!investmentsByPlatform[platform]) {
          investmentsByPlatform[platform] = 0;
        }
        investmentsByPlatform[platform] += loan.invested_amount;
      }

      // STATUT - initialisation lorsque le tableau avec ce statut n'est pas encore init
      if (!investmentsByStatus[statusLoan]) {
        investmentsByStatus[statusLoan] = 0;
      }
      investmentsByStatus[statusLoan] += loan.invested_amount;

      // TAUX - initialisation lorsque le tableau avec ce taux n'est pas encore init
      if (!investmentsByRate[Math.round(loan.interests_rate * 10) / 10]) {
        investmentsByRate[Math.round(loan.interests_rate * 10) / 10] = 0;
      }
      investmentsByRate[Math.round(loan.interests_rate * 10) / 10] +=
        loan.invested_amount;
    });

    // Par plateforme
    const platforms = Object.keys(investmentsByPlatform);
    const amountsPlatforms = platforms.map(
      (platform) => investmentsByPlatform[platform]
    );

    // Par statut
    const status = Object.keys(investmentsByStatus);
    const amountsStatus = status.map(
      (statusLoan) => investmentsByStatus[statusLoan]
    );

    // TAUX
    const rates = Object.keys(investmentsByRate);
    const amountsRates = rates.map((rateLoan) => investmentsByRate[rateLoan]);

    return {
      platforms,
      amountsPlatforms,
      status,
      amountsStatus,
      rates,
      amountsRates,
    };
  };

  // Calcule le nombre de projets par plateforme, par statut et par taux de rendement
  const calculateProjectsBy = (loans) => {
    const projectsByPlatform = {};
    const projectsByStatus = {};
    const projectsByRate = {};

    loans.forEach((loan) => {
      const statusLoan = getStatusFromId(loan.status);
      const platform = getPlatformFromId(loan.platform_id);

      // Par plateforme
      if (!projectsByPlatform[platform]) {
        projectsByPlatform[platform] = 0;
      }
      projectsByPlatform[platform] += 1;

      // Par statut
      if (!projectsByStatus[statusLoan]) {
        projectsByStatus[statusLoan] = 0;
      }
      projectsByStatus[statusLoan] += 1;

      // Par taux de rendement
      const rate = Math.round(loan.interests_rate * 10) / 10;
      if (!projectsByRate[rate]) {
        projectsByRate[rate] = 0;
      }
      projectsByRate[rate] += 1;
    });

    // Par plateforme
    const platforms = Object.keys(projectsByPlatform);
    const countsPlatforms = platforms.map(
      (platform) => projectsByPlatform[platform]
    );

    // Par statut
    const status = Object.keys(projectsByStatus);
    const countsStatus = status.map(
      (statusLoan) => projectsByStatus[statusLoan]
    );

    // Par taux de rendement
    const rates = Object.keys(projectsByRate);
    const countsRates = rates.map((rateLoan) => projectsByRate[rateLoan]);

    return {
      platforms,
      countsPlatforms,
      status,
      countsStatus,
      rates,
      countsRates,
    };
  };

  const calculateReceivedBy = (loans) => {
    const data = {};

    const backgroundColors = [
      "rgba(0, 0, 192, 0.2)", // Vert clair
      "rgba(255, 206, 86, 0.2)", // Jaune clair
    ];

    const borderColors = [
      "rgba(75, 192, 192, 1)", // Vert
      "rgba(255, 206, 86, 1)", // Jaune
    ];

    loans.forEach((loan) => {
      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = new Date(loan.max_maturity_date);
      const collectionDate = new Date(loan.collect_date);
      let maturityDate = new Date(loan.min_maturity_date);
      let lateLoan = 0;

      if (
        typeof maxMaturityDate !== "undefined" &&
        maxMaturityDate.length !== 0
      ) {
        const timeDiff = maxMaturityDate.getTime() - minMaturityDate.getTime();
        const halfTimeDiff = Math.round(timeDiff / 2);
        maturityDate.setTime(minMaturityDate.getTime() + halfTimeDiff);
      }

      // Vérifiez si maturityDate est antérieure à la date du jour et définir à maxMaturityDate si c'est le cas
      const currentDate = new Date();
      if (maturityDate < currentDate) {
        maturityDate = maxMaturityDate;
        lateLoan = 1;
      }

      const monthKey = moment(maturityDate).format("YYYY-MM");
      const monthDiffTerm =
        (minMaturityDate.getFullYear() - collectionDate.getFullYear()) * 12 +
        (minMaturityDate.getMonth() - collectionDate.getMonth());

      const platform = getPlatformFromId(loan.platform_id);

      // Initialisez data[platform] s'il n'est pas déjà défini
      if (!data[platform]) {
        data[platform] = {};
      }

      // Initialisez data[platform][monthKey] s'il n'est pas déjà défini
      if (!data[platform][monthKey]) {
        data[platform][monthKey] = {
          receivedByPlatformsOld: 0,
          receivedByPlatformsFuture: 0,
          receivedByPlatformsFutureLate: 0,
        };
      }

      // Mettez à jour les montants en fonction du statut du prêt
      if (loan.status === 5) {
        // Remboursé
        data[platform][monthKey].receivedByPlatformsOld +=
          loan.principal_repaid + loan.interests_received;
        /*
      if (monthKey === '2023-01')
      {
        console.log('OLD ', loan.project_name, ' | rembourse le ', monthKey, ' | pret de = ',loan.invested_amount, ' | ', loan.interests_rate,' | =', ((monthDiffTerm*loan.interests_rate/100)/12)*loan.invested_amount);
      }*/
      } else if (loan.status === 1) {
        // En cours
        if (lateLoan === 1) {
          data[platform][monthKey].receivedByPlatformsFutureLate +=
            loan.invested_amount -
            loan.principal_repaid +
            ((monthDiffTerm * loan.interests_rate) / 100 / 12) *
              loan.invested_amount -
            loan.interests_received;
        } else {
          data[platform][monthKey].receivedByPlatformsFuture +=
            loan.invested_amount -
            loan.principal_repaid +
            ((monthDiffTerm * loan.interests_rate) / 100 / 12) *
              loan.invested_amount -
            loan.interests_received;
        }

        /*if (monthKey === '2023-01')
      {
        console.log('FUTUR ', loan.project_name, ' | rembourse le ', monthKey, ' | pret de = ',loan.invested_amount, ' | ', loan.interests_rate,' | =', ((monthDiffTerm*loan.interests_rate/100)/12)*loan.invested_amount);
      }*/
      }
    });

    // Transformez l'objet 'data' en un format approprié pour être utilisé dans le graphique
    const labels = Object.keys(data)
      .flatMap((platform) => Object.keys(data[platform]))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    const datasets = Object.keys(data).flatMap((platform, index) => [
      {
        label: platform + " - Reçu",
        data: labels.map((month) => ({
          x: month,
          y: data[platform][month]?.receivedByPlatformsOld || 0,
        })),
        stack: platform,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: platform + " - À recevoir",
        data: labels.map((month) => ({
          x: month,
          y: data[platform][month]?.receivedByPlatformsFuture || 0,
        })),
        stack: platform,
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
      {
        label: platform + " - À recevoir en retard",
        data: labels.map((month) => ({
          x: month,
          y: data[platform][month]?.receivedByPlatformsFutureLate || 0,
        })),
        stack: platform,
        backgroundColor: "rgba(255, 22, 12, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
    ]);

    return {
      labels,
      datasets,
    };
  };

  const calculateNumberBy = (loans) => {
    const data = {};

    const backgroundColors = [
      "rgba(0, 0, 192, 0.2)", // Vert clair
      "rgba(255, 206, 86, 0.2)", // Jaune clair
    ];

    const borderColors = [
      "rgba(75, 192, 192, 1)", // Vert
      "rgba(255, 206, 86, 1)", // Jaune
    ];

    loans.forEach((loan) => {
      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = new Date(loan.max_maturity_date);
      const collectionDate = new Date(loan.collect_date);
      let maturityDate = new Date(loan.min_maturity_date);
      let lateLoan = 0;

      if (
        typeof maxMaturityDate !== "undefined" &&
        maxMaturityDate.length !== 0
      ) {
        const timeDiff = maxMaturityDate.getTime() - minMaturityDate.getTime();
        const halfTimeDiff = Math.round(timeDiff / 2);
        maturityDate.setTime(minMaturityDate.getTime() + halfTimeDiff);
      }

      // Vérifiez si maturityDate est antérieure à la date du jour et définir à maxMaturityDate si c'est le cas
      const currentDate = new Date();
      if (maturityDate < currentDate) {
        maturityDate = maxMaturityDate;
        lateLoan = 1;
      }

      const monthKey = moment(maturityDate).format("YYYY-MM");
      const monthDiffTerm =
        (minMaturityDate.getFullYear() - collectionDate.getFullYear()) * 12 +
        (minMaturityDate.getMonth() - collectionDate.getMonth());

      const platform = getPlatformFromId(loan.platform_id);

      // Initialisez data[platform] s'il n'est pas déjà défini
      if (!data[platform]) {
        data[platform] = {};
      }

      // Initialisez data[platform][monthKey] s'il n'est pas déjà défini
      if (!data[platform][monthKey]) {
        data[platform][monthKey] = {
          numberByPlatformsOld: 0,
          numberByPlatformsFuture: 0,
          numberByPlatformsLate: 0,
          loanNamesOld: [],
          loanNamesFuture: [],
          loanNamesLate: [],
        };
      }

      // Mettez à jour les montants en fonction du statut du prêt
      if (loan.status === 5) {
        // Remboursé
        data[platform][monthKey].numberByPlatformsOld++;
        data[platform][monthKey].loanNamesOld.push(loan.project_name);
      } else if (loan.status === 1) {
        // En cours
        if (lateLoan === 1) {
          data[platform][monthKey].numberByPlatformsLate++;
          data[platform][monthKey].loanNamesLate.push(loan.project_name);
        } else {
          data[platform][monthKey].numberByPlatformsFuture++;
          data[platform][monthKey].loanNamesFuture.push(loan.project_name);
        }
      }
    });

    // Transformez l'objet 'data' en un format approprié pour être utilisé dans le graphique
    const labels = Object.keys(data)
      .flatMap((platform) => Object.keys(data[platform]))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    /*const datasets = Object.keys(data).flatMap((platform, index) => ([
    {
      label: platform + ' - Terminés',
      data: labels.map(month => ({
        x: month,
        y: data[platform][month]?.numberByPlatformsOld || 0,
      })),
      //loanNames: labels.map(month => data[platform][month]?.loanNamesOld || []),
      loanNames: loanNamesByPlatform,
      stack: platform, 
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
    {
      label: platform + ' - En cours',
      data: labels.map(month => ({
        x: month,
        y: data[platform][month]?.numberByPlatformsFuture || 0,
      })),
      //loanNames: labels.map(month => data[platform][month]?.loanNamesFuture || []),
      loanNames: loanNamesByPlatform,
      stack: platform, 
      backgroundColor: 'rgba(255, 206, 86, 0.2)',
      borderColor: 'rgba(255, 206, 86, 1)',
      borderWidth: 1,
    },
    {
      label: platform + ' - En retard',
      data: labels.map(month => ({
        x: month,
        y: data[platform][month]?.numberByPlatformsLate || 0,
      })),
      //loanNames: labels.map(month => data[platform][month]?.loanNamesLate || []),
      loanNames: loanNamesByPlatform,
      stack: platform, 
      backgroundColor: 'rgba(255, 22, 12, 0.2)',
      borderColor: 'rgba(255, 206, 86, 1)',
      borderWidth: 1,
    },
  ]));  

  return {
    labels,
    datasets,
  };*/
    const datasets = Object.keys(data).flatMap((platform, index) => {
      const loanNames = {
        loanNamesOld: [],
        loanNamesFuture: [],
        loanNamesLate: [],
      };

      labels.forEach((month) => {
        loanNames.loanNamesOld.push(data[platform][month]?.loanNamesOld || []);
        loanNames.loanNamesFuture.push(
          data[platform][month]?.loanNamesFuture || []
        );
        loanNames.loanNamesLate.push(
          data[platform][month]?.loanNamesLate || []
        );
      });

      return [
        {
          label: platform + " - Terminés",
          data: labels.map((month) => ({
            x: month,
            y: data[platform][month]?.numberByPlatformsOld || 0,
          })),
          loanNames: loanNames.loanNamesOld,
          stack: platform,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
        {
          label: platform + " - En cours",
          data: labels.map((month) => ({
            x: month,
            y: data[platform][month]?.numberByPlatformsFuture || 0,
          })),
          loanNames: loanNames.loanNamesFuture,
          stack: platform,
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderWidth: 1,
        },
        {
          label: platform + " - En retard",
          data: labels.map((month) => ({
            x: month,
            y: data[platform][month]?.numberByPlatformsLate || 0,
          })),
          loanNames: loanNames.loanNamesLate,
          stack: platform,
          backgroundColor: "rgba(255, 22, 12, 0.2)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderWidth: 1,
        },
      ];
    });

    return {
      labels,
      datasets,
    };
  };

  // Récupère le nombre de projets financés par plateforme (quelque soit le statut)
  const totalProjectsByPlatform = (loans) => {
    const projectsByPlatform = loans.reduce((accumulator, loan) => {
      const platform = getPlatformFromId(loan.platform_id);
      let status = "";

      if (loan.status === 5) {
        status = "Remboursé";
      } else {
        status = "En cours";
      }

      if (!accumulator[platform]) {
        accumulator[platform] = {};
      }

      if (!accumulator[platform][status]) {
        accumulator[platform][status] = 1;
      } else {
        accumulator[platform][status]++;
      }
      return accumulator;
    }, {});

    return projectsByPlatform;
  };

  // Récupère le montant financé par plateforme (quelque soit le statut)
  const totalAmountsByPlatform = (loans) => {
    const amountsByPlatform = loans.reduce((accumulator, loan) => {
      const platform = getPlatformFromId(loan.platform_id);
      let status = "";

      if (loan.status === 5) {
        status = "Remboursé";
      } else {
        status = "En cours";
      }

      if (!accumulator[platform]) {
        accumulator[platform] = {};
      }

      if (!accumulator[platform][status]) {
        accumulator[platform][status] = loan.invested_amount;
      } else {
        accumulator[platform][status] += loan.invested_amount;
      }
      return accumulator;
    }, {});

    return amountsByPlatform;
  };

  /*
// Récupère le nombre de projets financés par plateforme (quelque soit le statut)
const totalProjectsByPlatform = (loans) => {
  const projectsByPlatform = loans.reduce((accumulator, loan) => { 
    const platform = getPlatformFromId(loan.platform_id);
    if (!accumulator[platform]) {
      accumulator[platform] = 1;
    } else {
      accumulator[platform]++;
    }
    return accumulator;
  }, {});

  return projectsByPlatform;
};*/

  // Modifie les valeurs de remboursements à venir si on change le tableau
  const { labels, principalData, interestData } = useMemo(
    () => calculateMonthlyRepayments(filteredLoans),
    [filteredLoans]
  );

  // Montants investis par plateforme pour les prêts en cours
  const {
    platforms,
    amountsPlatforms,
    status,
    amountsStatus,
    rates,
    amountsRates,
  } = useMemo(() => calculateInvestedAmountBy(filteredLoans), [filteredLoans]);

  // Appelle la fonction de calcul des sommes reçus et à recevoir par plateforme
  const receivedBy = calculateReceivedBy(allLoans);
  //console.log('receivedBy = ', receivedBy.datasets);

  const numberBy = calculateNumberBy(allLoans);
  //console.log('numberBy = ', numberBy.datasets);

  // Nombre de projets par plateforme
  const projectsByPlatform = totalProjectsByPlatform(allLoans);

  // Montants investis par plateforme
  const amountsByPlatform = totalAmountsByPlatform(allLoans);

  // Gère le clic sur le bouton d'affichage / masquage du tableau
  const handleToggleTable = () => {
    setShowTable(!showTable);
  };

  // Rendement global pondéré par le montant investi dans chaque projet et par plateforme (optionnel) et par statut (optionnel)
  const weightedAverageReturnRate = (
    loans,
    statusFilter = null,
    platformFilter = null
  ) => {
    if (!loans || loans.length === 0) {
      return 0;
    }

    let totalInvested = 0;
    let totalWeightedReturnRate = 0;

    loans.forEach((loan) => {
      if (
        (statusFilter === null || loan.status === statusFilter) &&
        (platformFilter === null || loan.platform_id === platformFilter) &&
        loan.invested_amount &&
        !isNaN(loan.invested_amount) &&
        loan.interests_rate &&
        !isNaN(loan.interests_rate)
      ) {
        totalInvested += parseFloat(loan.invested_amount);
        totalWeightedReturnRate +=
          parseFloat(loan.invested_amount) * parseFloat(loan.interests_rate);
      }
    });

    if (totalInvested === 0) {
      return 0;
    }

    return (totalWeightedReturnRate / totalInvested).toFixed(2);
  };

  const weightedAvgRate = weightedAverageReturnRate(allLoans); // Aucun filtre de statut appliqué

  const weightedAvgRateRepaid = weightedAverageReturnRate(allLoans, 5); // Applique un filtre de statut Remboursé
  const weightedAvgRateInProgress = weightedAverageReturnRate(allLoans, 1); // Applique un filtre de statut En cours

  // Total restant à percevoir avec la possibilité de spécifier la plateforme
  const remainingAmountToReceive = (loans, platformFilter) => {
    const remainingAmount = {
      total: 0,
      oneMonth: 0,
      threeMonths: 0,
      sixMonths: 0,
      oneYear: 0,
      moreOneYear: 0,
    };

    const currentDate = new Date();

    loans.forEach((loan) => {
      //const platform = getPlatformFromId(loan.platform_id);

      if (
        loan.status !== 5 &&
        (!platformFilter || platformFilter === loan.platform_id)
      ) {
        const investedAmount = parseFloat(loan.invested_amount) || 0;
        const principalRepaid = parseFloat(loan.principal_repaid) || 0;
        const interestsReceived = parseFloat(loan.interests_received) || 0;

        const remaining =
          investedAmount - (principalRepaid + interestsReceived);

        const minMaturityDate = new Date(loan.min_maturity_date);
        const maxMaturityDate = loan.max_maturity_date
          ? new Date(loan.max_maturity_date)
          : minMaturityDate;
        const referenceDate =
          maxMaturityDate.getTime() > minMaturityDate.getTime()
            ? maxMaturityDate
            : minMaturityDate;
        const remainingMonths =
          (referenceDate.getFullYear() - currentDate.getFullYear()) * 12 +
          (referenceDate.getMonth() - currentDate.getMonth());

        remainingAmount.total += remaining;

        if (remainingMonths <= 1) {
          remainingAmount.oneMonth += remaining;
        } else if (remainingMonths <= 3) {
          remainingAmount.threeMonths += remaining;
        } else if (remainingMonths <= 6) {
          remainingAmount.sixMonths += remaining;
        } else if (remainingMonths <= 12) {
          remainingAmount.oneYear += remaining;
        } else {
          remainingAmount.moreOneYear += remaining;
        }
      }
    });

    return remainingAmount;
  };

  const remainingAmount = remainingAmountToReceive(allLoans);
  //console.log("Montant restant à percevoir pour la plateforme A :", remainingAmount);

  // Tableau prévisionnel des remboursements
  const loansInChronologicalOrder = (loans, platformFilter) => {
    const currentDate = new Date();
    const filteredLoans = loans
      .filter(
        (loan) =>
          loan.status !== 5 &&
          (!platformFilter || platformFilter === loan.platform_id)
      )
      .map((loan) => {
        const projectName = loan.project_name;
        const platform = getPlatformFromId(loan.platform_id);

        const collectionDate = new Date(loan.collect_date);
        //moment(loan.collect_dat).format("YYYY-MM-DD");

        const minMaturityDate = new Date(loan.min_maturity_date);
        const maxMaturityDate = loan.max_maturity_date
          ? new Date(loan.max_maturity_date)
          : minMaturityDate;

        let repaymentDate = new Date(
          minMaturityDate.getTime() +
            (maxMaturityDate.getTime() - minMaturityDate.getTime()) / 2
        );
        let remainingMonths =
          (repaymentDate.getFullYear() - currentDate.getFullYear()) * 12 +
          (repaymentDate.getMonth() - currentDate.getMonth());

        if (remainingMonths < 0 && loan.max_maturity_date) {
          repaymentDate = maxMaturityDate;
          remainingMonths =
            (repaymentDate.getFullYear() - currentDate.getFullYear()) * 12 +
            (repaymentDate.getMonth() - currentDate.getMonth());
        }

        const investedAmount = parseFloat(loan.invested_amount) || 0;
        const principalRepaid = parseFloat(loan.principal_repaid) || 0;
        const interestsReceived = parseFloat(loan.interests_received) || 0;

        const remainingPrincipal = investedAmount - principalRepaid;

        const monthDiffTerm =
          (repaymentDate.getFullYear() - collectionDate.getFullYear()) * 12 +
          (repaymentDate.getMonth() - collectionDate.getMonth());

        const remainingInterests =
          ((monthDiffTerm * loan.interests_rate) / 100 / 12) * investedAmount -
          interestsReceived;
        const remainingTotal = remainingPrincipal + remainingInterests;

        const interestRate = parseFloat(loan.interests_rate);

        return {
          platform,
          projectName,
          collectionDate,
          repaymentDate,
          remainingMonths,
          remainingTotal,
          principalRepaid,
          remainingPrincipal,
          interestsReceived,
          remainingInterests,
          interestRate,
        };
      })
      .sort((a, b) => a.repaymentDate - b.repaymentDate);

    return filteredLoans;
  };

  const loansChronologicalTable = loansInChronologicalOrder(allLoans);
  //console.log("Tableau des prêts en cours par ordre chronologique pour la plateforme A :", loansChronologicalTable);

  function getBackgroundColor(remainingMonths) {
    if (remainingMonths <= 1) {
      return "rgba(255, 99, 132, 0.2)";
    } else if (remainingMonths <= 3) {
      return "rgba(54, 162, 235, 0.2)";
    } else if (remainingMonths <= 6) {
      return "rgba(255, 206, 86, 0.2)";
    } else {
      return "rgba(75, 192, 192, 0.2)";
    }
  }

  const averageProjectDuration = (loans, platformFilter, statusFilter) => {
    const currentDate = new Date();
    const filteredLoans = loans.filter((loan) => {
      const platformMatch =
        !platformFilter || platformFilter === loan.platform_id;
      const statusMatch = !statusFilter || statusFilter === loan.status;
      return platformMatch && statusMatch;
    });

    if (filteredLoans.length === 0) {
      return 0;
    }

    const totalDuration = filteredLoans.reduce((accumulator, loan) => {
      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = loan.max_maturity_date
        ? new Date(loan.max_maturity_date)
        : minMaturityDate;
      let referenceDate = new Date(
        minMaturityDate.getTime() +
          (maxMaturityDate.getTime() - minMaturityDate.getTime()) / 2
      );
      const remainingMonths =
        (referenceDate.getFullYear() - currentDate.getFullYear()) * 12 +
        (referenceDate.getMonth() - currentDate.getMonth());

      if (remainingMonths <= 0 && loan.max_maturity_date) {
        referenceDate = maxMaturityDate;
      }

      const loanCollectDate = new Date(loan.collect_date);
      const loanDuration =
        (referenceDate - loanCollectDate) / (1000 * 60 * 60 * 24 * 30); // duration in months
      return accumulator + loanDuration;
    }, 0);

    return (totalDuration / filteredLoans.length).toFixed(1);
  };
  const avgProjectDuration = averageProjectDuration(allLoans, null, null);
  const avgRepaidProjectDuration = averageProjectDuration(allLoans, null, 5);
  const avgInProgressProjectDuration = averageProjectDuration(
    allLoans,
    null,
    1
  );

  /*
  const averageProjectDurationPerMonth = (
    loans,
    platformFilter,
    statusFilter
  ) => {
    const currentDate = new Date();
    const filteredLoans = loans.filter((loan) => {
      const platformMatch =
        !platformFilter || platformFilter === loan.platform_id;
      const statusMatch = !statusFilter || statusFilter === loan.status;
      return platformMatch && statusMatch;
    });

    const groupedLoans = {};

    filteredLoans.forEach((loan) => {
      const collectDate = new Date(loan.collect_date);
      const monthYear =
        collectDate.getFullYear() +
        "-" +
        (collectDate.getMonth() + 1).toString().padStart(2, "0");

      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = loan.max_maturity_date
        ? new Date(loan.max_maturity_date)
        : minMaturityDate;
      const referenceDate = new Date(
        minMaturityDate.getTime() +
          (maxMaturityDate.getTime() - minMaturityDate.getTime()) / 2
      );
      const loanDuration =
        (referenceDate.getTime() - collectDate.getTime()) /
        (1000 * 60 * 60 * 24 * 30); // duration in months

      if (groupedLoans.hasOwnProperty(monthYear)) {
        groupedLoans[monthYear].totalDuration += loanDuration;
        groupedLoans[monthYear].count += 1;
        groupedLoans[monthYear].projects.push(loan.project_name);
      } else {
        groupedLoans[monthYear] = {
          totalDuration: loanDuration,
          count: 1,
          projects: [loan.project_name],
        };
      }
    });

    const result = Object.entries(groupedLoans).map(([monthYear, data]) => {
      const [year, month] = monthYear.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10) - 1, // zero-based month
        averageDuration: (data.totalDuration / data.count).toFixed(1),
        projects: data.projects,
      };
    });

    return result.sort((a, b) => {
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      return a.month - b.month;
    });
  };
  */
  const averageProjectDurationPerMonth = (
    loans,
    platformFilter,
    statusFilter
  ) => {
    const currentDate = new Date();
    const filteredLoans = loans.filter((loan) => {
      const platformMatch =
        !platformFilter || platformFilter === loan.platform_id;
      const statusMatch = !statusFilter || statusFilter === loan.status;
      return platformMatch && statusMatch;
    });

    const groupedLoans = {};

    filteredLoans.forEach((loan) => {
      const collectDate = new Date(loan.collect_date);
      const monthYear =
        collectDate.getFullYear() +
        "-" +
        (collectDate.getMonth() + 1).toString().padStart(2, "0");

      const minMaturityDate = new Date(loan.min_maturity_date);
      const maxMaturityDate = loan.max_maturity_date
        ? new Date(loan.max_maturity_date)
        : minMaturityDate;
      const referenceDate = new Date(
        minMaturityDate.getTime() +
          (maxMaturityDate.getTime() - minMaturityDate.getTime()) / 2
      );
      const loanDuration =
        (referenceDate.getTime() - collectDate.getTime()) /
        (1000 * 60 * 60 * 24 * 30); // duration in months

      if (groupedLoans.hasOwnProperty(monthYear)) {
        if (loan.status === 5) {
          groupedLoans[monthYear].totalDurationRepaid += loanDuration;
          groupedLoans[monthYear].countRepaid += 1;
          groupedLoans[monthYear].repaidProjects.push(loan.project_name);
        } else {
          groupedLoans[monthYear].totalDurationOther += loanDuration;
          groupedLoans[monthYear].countOther += 1;
          groupedLoans[monthYear].otherProjects.push(loan.project_name);
        }
      } else {
        groupedLoans[monthYear] = {
          totalDurationRepaid: loan.status === 5 ? loanDuration : 0,
          countRepaid: loan.status === 5 ? 1 : 0,
          totalDurationOther: loan.status !== 5 ? loanDuration : 0,
          countOther: loan.status !== 5 ? 1 : 0,
          repaidProjects: loan.status === 5 ? [loan.project_name] : [],
          otherProjects: loan.status !== 5 ? [loan.project_name] : [],
        };
      }
    });

    const result = Object.entries(groupedLoans).map(([monthYear, data]) => {
      const [year, month] = monthYear.split("-");
      return {
        year: parseInt(year, 10),
        month: parseInt(month, 10) - 1, // zero-based month
        averageDurationRepaid: (
          data.totalDurationRepaid / data.countRepaid
        ).toFixed(1),
        averageDurationOther: (
          data.totalDurationOther / data.countOther
        ).toFixed(1),
        repaidProjects: data.repaidProjects,
        otherProjects: data.otherProjects,
      };
    });

    return result.sort((a, b) => {
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      return a.month - b.month;
    });
  };

  const avgProjectsDuration = averageProjectDurationPerMonth(allLoans);
  //  console.log('avgProjectsDuration : ',avgProjectsDuration);

  /*
const countProjectsByCollectMonth = (loans, platformId = null, statusId = null) => {
  const projectsByCollectMonth = {};

  loans.forEach((loan) => {
    if (
      (platformId === null || loan.platform_id === platformId) &&
      (statusId === null || loan.status === statusId)
    ) {
      const collectDate = new Date(loan.collect_date);
      const collectMonthYear = new Date(collectDate.getFullYear(), collectDate.getMonth());

      if (!projectsByCollectMonth[collectMonthYear]) {
        projectsByCollectMonth[collectMonthYear] = 0;
      }
      projectsByCollectMonth[collectMonthYear] += 1;
    }
  });

  const labels = Object.keys(projectsByCollectMonth)
    .sort()
    .map((label) => new Date(label));
  const data = labels.map((label) => projectsByCollectMonth[label]);

  return {
    labels,
    data,
  };
};


const projectsByCollectMonthData = countProjectsByCollectMonth(allLoans, null, null);
*/
  const countProjectsByCollectMonth = (
    loans,
    platformId = null,
    statusId = null
  ) => {
    const projectsByCollectMonth = {};

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!projectsByCollectMonth[collectMonthYear]) {
          projectsByCollectMonth[collectMonthYear] = { count: 0, projects: [] };
        }
        projectsByCollectMonth[collectMonthYear].count += 1;
        projectsByCollectMonth[collectMonthYear].projects.push(
          loan.project_name
        );
      }
    });

    const labels = Object.keys(projectsByCollectMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => projectsByCollectMonth[label].count);
    const projects = labels.map(
      (label) => projectsByCollectMonth[label].projects
    );

    return {
      labels,
      data,
      projects,
    };
  };

  const projectsByCollectMonthData = countProjectsByCollectMonth(
    allLoans,
    null,
    null
  );
  //console.log(projectsByCollectMonthData);

  const projectsByCollectMonthDataCustom = countProjectsByCollectMonth(
    filteredLoans,
    null,
    null
  );
  //console.log(projectsByCollectMonthData);

  const countRepaidProjectsByMonth = (loans, platformId, statusId) => {
    const projectsByMonth = {};

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        let repaymentDate;
        const minMaturityDate = new Date(loan.min_maturity_date);
        const maxMaturityDate = new Date(loan.max_maturity_date);

        if (isNaN(maxMaturityDate.getTime())) {
          repaymentDate = minMaturityDate;
        } else {
          const averageTimestamp =
            (minMaturityDate.getTime() + maxMaturityDate.getTime()) / 2;
          repaymentDate = new Date(averageTimestamp);
        }

        // Vérification pour les projets avec un statut d'ID 1
        if (loan.status === 1 && repaymentDate < currentDate) {
          repaymentDate = maxMaturityDate;
        } else if (loan.status === 5 && repaymentDate > currentDate) {
          if (minMaturityDate < currentDate) {
            repaymentDate = minMaturityDate;
          } else {
            repaymentDate = currentDate;
          }
        }

        const repaymentMonthYear = new Date(
          repaymentDate.getFullYear(),
          repaymentDate.getMonth()
        );

        if (!projectsByMonth[repaymentMonthYear]) {
          projectsByMonth[repaymentMonthYear] = { count: 0, projects: [] };
        }
        projectsByMonth[repaymentMonthYear].count += 1;
        projectsByMonth[repaymentMonthYear].projects.push(loan.project_name);
      }
    });

    const labels = Object.keys(projectsByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => projectsByMonth[label].count);
    const projects = labels.map((label) => projectsByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };

  // Renvoie un tableau avec la liste des projets dont soit le statut est en retard (valeur 2) soit le statut est en cours (valeur 1) et la date max_maturity_date est antérieure à la date du jour.
  const getLateAndInProgressProjects = (loans) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const filteredProjects = loans
      .filter((loan) => {
        const maxMaturityDate = new Date(loan.max_maturity_date);
        return (
          (loan.status === 1 && maxMaturityDate < currentDate) ||
          loan.status === 2
        );
      })
      .map((loan) => {
        const projectName = loan.project_name;
        const status = loan.status;
        const collectDate = new Date(loan.collect_date);
        const estimatedRepaymentDate = new Date(loan.max_maturity_date);
        const projectRate = loan.interests_rate;
        const loanAmount = loan.invested_amount;
        const remainingCapital = loan.invested_amount - loan.principal_repaid;
        const paidInterests = loan.interests_received;

        return {
          projectName,
          status,
          collectDate,
          estimatedRepaymentDate,
          projectRate,
          loanAmount,
          remainingCapital,
          paidInterests,
        };
      });

    return filteredProjects;
  };

  // Nombre de projets remboursés chaque mois (et donc déjà en statut remboursé)
  const repaidProjectsByMonthData = countRepaidProjectsByMonth(
    allLoans,
    null,
    5
  );

  const repaidProjectsByMonthDataCustom = countRepaidProjectsByMonth(
    filteredLoans,
    null,
    5
  );

  // Estimation du nombre de projets à rembourser (et donc toujours en cours)
  const futureRepaidProjectsByMonthData = countRepaidProjectsByMonth(
    allLoans,
    null,
    1
  );

  // Estimation du nombre de projets à rembourser (et donc toujours en cours)
  const futureRepaidProjectsByMonthDataCustom = countRepaidProjectsByMonth(
    filteredLoans,
    null,
    1
  );

  // Tableau avec avec la liste des projets officiellement en retard ou a priori en retard
  const arrayLateLoans = getLateAndInProgressProjects(allLoans);

  const sumInvestedAmountByCollectMonth = (
    loans,
    platformId = null,
    statusId = null
  ) => {
    const investmentsByCollectMonth = {};

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!investmentsByCollectMonth[collectMonthYear]) {
          investmentsByCollectMonth[collectMonthYear] = {
            investedAmount: 0,
            projects: [],
          };
        }
        investmentsByCollectMonth[collectMonthYear].investedAmount +=
          loan.invested_amount;
        investmentsByCollectMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );
      }
    });

    const labels = Object.keys(investmentsByCollectMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map(
      (label) => investmentsByCollectMonth[label].investedAmount
    );
    const projects = labels.map(
      (label) => investmentsByCollectMonth[label].projects
    );

    return {
      labels,
      data,
      projects,
    };
  };

  const investmentsByCollectMonthData = sumInvestedAmountByCollectMonth(
    allLoans,
    null,
    null
  );
  //console.log('investmentsByCollectMonthData : ',investmentsByCollectMonthData);

  const sumRepaidAmountByMonth = (loans, platformId, statusId, factor = 1) => {
    const repaidAmountByMonth = {};

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    loans.forEach((loan) => {
      const collectionDate = new Date(loan.collect_date);
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        let repaymentDate;
        const minMaturityDate = new Date(loan.min_maturity_date);
        const maxMaturityDate = new Date(loan.max_maturity_date);

        if (isNaN(maxMaturityDate.getTime())) {
          repaymentDate = minMaturityDate;
        } else {
          const averageTimestamp =
            (minMaturityDate.getTime() + maxMaturityDate.getTime()) / 2;
          repaymentDate = new Date(averageTimestamp);
        }

        // Vérification pour les projets avec un statut d'ID 1
        if (loan.status === 1 && repaymentDate < currentDate) {
          repaymentDate = maxMaturityDate;
        } else if (loan.status === 5 && repaymentDate > currentDate) {
          if (minMaturityDate < currentDate) {
            repaymentDate = minMaturityDate;
          } else {
            repaymentDate = currentDate;
          }
        }

        //const collectDate = new Date(loan.collect_date);
        const paidMonthYear = new Date(
          repaymentDate.getFullYear(),
          repaymentDate.getMonth()
        );

        if (!repaidAmountByMonth[paidMonthYear]) {
          repaidAmountByMonth[paidMonthYear] = {
            totalRepaidAmount: 0,
            projects: [],
          };
        }

        if (loan.status === 5) {
          repaidAmountByMonth[paidMonthYear].totalRepaidAmount +=
            loan.invested_amount * factor;
          repaidAmountByMonth[paidMonthYear].projects.push(
            `\n${loan.project_name} (${
              loan.invested_amount + loan.interests_received
            }€)`
          );
        } else if (loan.status === 1) {
          /*
            repayments[monthKey].interests_received +=
          ((monthDiffTerm * loan.interests_rate) / 100 / 12)
          */
          const monthDiffTerm =
            (repaymentDate.getFullYear() - collectionDate.getFullYear()) * 12 +
            (repaymentDate.getMonth() - collectionDate.getMonth());

          const interests_to_receive =
            ((monthDiffTerm * loan.interests_rate) / 100 / 12) *
            loan.invested_amount;
          const total_to_receive = loan.invested_amount + interests_to_receive;
          repaidAmountByMonth[paidMonthYear].totalRepaidAmount +=
            total_to_receive * factor;
          //repaidAmountByMonth[paidMonthYear].projects.push(`\n${loan.project_name} (${loan.invested_amount + ((monthDiffTerm * loan.interests_rate) / 100 / 12)}€ `);
          repaidAmountByMonth[paidMonthYear].projects.push(
            `\n${
              loan.project_name
            } - ${monthDiffTerm} mois (${total_to_receive.toFixed(2)} € =  ${
              loan.invested_amount
            } + ${interests_to_receive.toFixed(2)})`
          );
        }
      }
    });

    const labels = Object.keys(repaidAmountByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) =>
      repaidAmountByMonth[label].totalRepaidAmount.toFixed(2)
    );
    const projects = labels.map((label) => repaidAmountByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };

  const repaidAmountsByCollectMonthData = sumRepaidAmountByMonth(
    allLoans,
    null,
    5,
    -1
  );
  //console.log('repaidAmountsByCollectMonthData : ',repaidAmountsByCollectMonthData);

  // Montants restant à rembourser chaque mois (projets en cours = statut = 1)
  const futureRepaidAmountsByMonthData = sumRepaidAmountByMonth(
    allLoans,
    null,
    1,
    1
  );
  //console.log('futureRepaidAmountsByMonthData : ',futureRepaidAmountsByMonthData);

  // Évolution du capital investi (donc uniquement en prenant )
  const cumulatedAmountByMonth = (
    loans,
    platformId = null,
    statusId = null,
    repaidAmountsByCollectMonthData
  ) => {
    const cumulatedByMonth = {};

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!cumulatedByMonth[collectMonthYear]) {
          cumulatedByMonth[collectMonthYear] = {
            investedAmount: 0,
            projects: [],
          };
        }
        cumulatedByMonth[collectMonthYear].investedAmount +=
          loan.invested_amount;
        cumulatedByMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );
      }
    });
    console.log("cumulatedByMonth1: ", cumulatedByMonth);

    // Soustraction des remboursements correspondants à chaque mois

    const repaidAmountsByCollectMonth = {};
    repaidAmountsByCollectMonthData.labels.forEach((label, i) => {
      const repaidMonthYear = new Date(label.getFullYear(), label.getMonth());
      repaidAmountsByCollectMonth[repaidMonthYear] =
        repaidAmountsByCollectMonthData.data[i];
    });
    console.log("repaid: ", repaidAmountsByCollectMonthData);

    Object.keys(cumulatedByMonth).forEach((key) => {
      const repaidAmount = repaidAmountsByCollectMonth[new Date(key)] || 0;
      cumulatedByMonth[key].investedAmount -= repaidAmount;
    });
    console.log("cumulatedByMonth2: ", cumulatedByMonth);

    // Récupération des labels et des données
    const labels = Object.keys(cumulatedByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => cumulatedByMonth[label].investedAmount);
    const projects = labels.map((label) => cumulatedByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };

  const sumInvestedAndAmountByCollectMonth = (
    loans,
    platformId = null,
    statusId = null
  ) => {
    const investmentsByCollectMonth = {};

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!investmentsByCollectMonth[collectMonthYear]) {
          investmentsByCollectMonth[collectMonthYear] = {
            investedAmount: 0,
            projects: [],
          };
        }
        investmentsByCollectMonth[collectMonthYear].investedAmount +=
          loan.invested_amount;
        investmentsByCollectMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );
      }
    });

    const labels = Object.keys(investmentsByCollectMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map(
      (label) => investmentsByCollectMonth[label].investedAmount
    );
    const projects = labels.map(
      (label) => investmentsByCollectMonth[label].projects
    );

    return {
      labels,
      data,
      projects,
    };
  };

  const sumAmountsByMonth = (
    loans,
    platformId = null,
    statusId = null,
    factor = 1
  ) => {
    const amountsByMonth = {};

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        // Process invested amounts
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!amountsByMonth[collectMonthYear]) {
          amountsByMonth[collectMonthYear] = {
            investedAmount: 0,
            repaidAmount: 0,
            investedProjects: [],
            repaidProjects: [],
          };
        }
        amountsByMonth[collectMonthYear].investedAmount += loan.invested_amount;
        amountsByMonth[collectMonthYear].investedProjects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );

        // Process repaid amounts
        let repaymentDate;
        const minMaturityDate = new Date(loan.min_maturity_date);
        const maxMaturityDate = new Date(loan.max_maturity_date);

        if (isNaN(maxMaturityDate.getTime())) {
          repaymentDate = minMaturityDate;
        } else {
          const averageTimestamp =
            (minMaturityDate.getTime() + maxMaturityDate.getTime()) / 2;
          repaymentDate = new Date(averageTimestamp);
        }

        if (loan.status === 1 && repaymentDate < currentDate) {
          repaymentDate = maxMaturityDate;
        } else if (loan.status === 5 && repaymentDate > currentDate) {
          if (minMaturityDate < currentDate) {
            repaymentDate = minMaturityDate;
          } else {
            repaymentDate = currentDate;
          }
        }

        const paidMonthYear = new Date(
          repaymentDate.getFullYear(),
          repaymentDate.getMonth()
        );

        if (!amountsByMonth[paidMonthYear]) {
          amountsByMonth[paidMonthYear] = {
            investedAmount: 0,
            repaidAmount: 0,
            investedProjects: [],
            repaidProjects: [],
          };
        }

        if (loan.status === 5) {
          amountsByMonth[paidMonthYear].repaidAmount +=
            loan.invested_amount * factor;
          amountsByMonth[paidMonthYear].repaidProjects.push(
            `\n${loan.project_name} (${
              loan.invested_amount + loan.interests_received
            }€)`
          );
        } else if (loan.status === 1) {
          const monthDiffTerm =
            (repaymentDate.getFullYear() - collectDate.getFullYear()) * 12 +
            (repaymentDate.getMonth() - collectDate.getMonth());

          const interests_to_receive =
            ((monthDiffTerm * loan.interests_rate) / 100 / 12) *
            loan.invested_amount;
          const total_to_receive = loan.invested_amount + interests_to_receive;
          amountsByMonth[paidMonthYear].repaidAmount +=
            total_to_receive * factor;
          amountsByMonth[paidMonthYear].repaidProjects.push(
            `\n${loan.project_name}
        } - ${monthDiffTerm} mois (${total_to_receive.toFixed(2)} € =  ${
              loan.invested_amount
            } + ${interests_to_receive.toFixed(2)})`
          );
        }
      }
    });

    const labels = Object.keys(amountsByMonth)
      .sort()
      .map((label) => new Date(label));
    const investedData = labels.map(
      (label) => amountsByMonth[label].investedAmount
    );
    const repaidData = labels.map((label) =>
      amountsByMonth[label].repaidAmount.toFixed(2)
    );
    const investedProjects = labels.map(
      (label) => amountsByMonth[label].investedProjects
    );
    const repaidProjects = labels.map(
      (label) => amountsByMonth[label].repaidProjects
    );

    return {
      labels,
      investedData,
      repaidData,
      investedProjects,
      repaidProjects,
    };
  };

  const ccc = sumAmountsByMonth(allLoans, null, null);

  /*
const cumulatedPrincipalByMonth = cumulatedAmountByMonth (
      allLoans,
      null,
      null,
      sumRepaidAmountByMonth(allLoans, null, 5)
    );

    console.log('cumulatedAmountByMonthFinal: ',cumulatedPrincipalByMonth);

*/

  /*const cumulatedAmount = (loans, platformId = null, statusId = null) => {
      const cumulated = [];
    
      let investedAmount = 0;
    
      loans.forEach((loan) => {
        if (
          (platformId === null || loan.platform_id === platformId) &&
          (statusId === null || loan.status === statusId)
        ) {
          investedAmount += loan.invested_amount;
        }
      });
    
      const repaidAmountsByMonth = sumRepaidAmountByMonth(loans, platformId, 5);
    
      repaidAmountsByMonth.labels.forEach((label, i) => {
        const monthYear = new Date(label.getFullYear(), label.getMonth());
        investedAmount -= repaidAmountsByMonth.data[i];
      });
    
      cumulated.push({
        investedAmount,
        date: new Date(0),
      });
    
      loans.forEach((loan) => {
        if (
          (platformId === null || loan.platform_id === platformId) &&
          (statusId === null || loan.status === statusId)
        ) {
          const collectDate = new Date(loan.collect_date);
          
          const monthYear = new Date(collectDate.getFullYear(), collectDate.getMonth());
          console.log('monthYear : ',monthYear);
    
          const lastCumulated = cumulated[cumulated.length - 1];
    

          //console.log('lastCumulated : ',lastCumulated);

          if (lastCumulated.date.getFullYear() === monthYear.getFullYear() && lastCumulated.date.getMonth() === monthYear.getMonth()) {
            lastCumulated.investedAmount += loan.invested_amount;
            lastCumulated.projects.push(
              "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
            );
          } else {
            cumulated.push({
              investedAmount: lastCumulated.investedAmount + loan.invested_amount,
              projects: ["\n" + loan.project_name + " (" + loan.invested_amount + "€)"],
              date: monthYear,
            });
          }
        }
      });
    
      cumulated.forEach((c, i) => {
        if (i > 0) {
          c.investedAmount += cumulated[i - 1].investedAmount;
        }
      });
    
      const labels = cumulated.map((c) => c.date);
      const data = cumulated.map((c) => c.investedAmount.toFixed(2));
      const projects = cumulated.map((c) => c.projects);
    
      return {
        labels,
        data,
        projects,
      };
    };*/

  /*
  Fonction qui est OK   
  const cumulatedAmount = (
    loans,
    platformId = null,
    statusId = null,
    repaid
  ) => {
    const cumulatedByMonth = {};

    // Initialisation de la variable lastCumulated à 0
    let lastCumulated = 0;

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!cumulatedByMonth[collectMonthYear]) {
          cumulatedByMonth[collectMonthYear] = {
            investedAmount: 0,
            projects: [],
          };
        }

        cumulatedByMonth[collectMonthYear].investedAmount +=
          loan.invested_amount;
        cumulatedByMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );
      }
    });

    // Soustraction des remboursements correspondants à chaque mois
    const repaidAmountsByCollectMonth = {};
    repaid.labels.forEach((label, i) => {
      const repaidMonthYear = new Date(label.getFullYear(), label.getMonth());
      repaidAmountsByCollectMonth[repaidMonthYear] = {
        repaidAmount: repaid.data[i],
        projects: repaid.projects[i],
      };
    });

    Object.keys(cumulatedByMonth).forEach((key) => {
      const repaidAmount =
        repaidAmountsByCollectMonth[new Date(key)]?.repaidAmount || 0;
      const repaidProjects =
        repaidAmountsByCollectMonth[new Date(key)]?.projects || [];

      cumulatedByMonth[key].investedAmount =
        lastCumulated + cumulatedByMonth[key].investedAmount - repaidAmount;

      cumulatedByMonth[key].projects.push(
        ...repaidProjects.map(
          (project) =>
            "\nRemboursement :  " +
            project.replace(/^\s*\n/, "").replace(/\((.*)\)/g, "(-$1)")
        )
      );

      lastCumulated = cumulatedByMonth[key].investedAmount;
    });
   
    // Récupération des labels et des données
    const labels = Object.keys(cumulatedByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => cumulatedByMonth[label].investedAmount);
    const projects = labels.map((label) => cumulatedByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };
  */

  const cumulatedAmount = (
    loans,
    platformId = null,
    statusId = null,
    repaid
  ) => {
    const cumulatedByMonth = {};

    // Initialisation de la variable lastCumulated à 0
    let lastCumulated = 0;

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!cumulatedByMonth[collectMonthYear]) {
          cumulatedByMonth[collectMonthYear] = {
            investedAmount: 0,
            projects: [],
          };
        }

        cumulatedByMonth[collectMonthYear].investedAmount +=
          loan.invested_amount;
        cumulatedByMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.invested_amount + "€)"
        );
      }
    });

    // Soustraction des remboursements correspondants à chaque mois
    const repaidAmountsByCollectMonth = {};
    repaid.labels.forEach((label, i) => {
      const repaidMonthYear = new Date(label.getFullYear(), label.getMonth());
      repaidAmountsByCollectMonth[repaidMonthYear] = {
        repaidAmount: repaid.data[i],
        projects: repaid.projects[i],
      };
    });

    const lastCumulatedMonth = new Date(
      Object.keys(cumulatedByMonth).sort().pop()
    );

    const currentDate = new Date();

    let lastInvestedAmount =
      cumulatedByMonth[lastCumulatedMonth]?.investedAmount || 0;
    //console.log('debug - lastInvestedAmount: ', lastInvestedAmount, ' pour le mois ', lastCumulatedMonth);

    for (
      let month = new Date(
        lastCumulatedMonth.getFullYear(),
        lastCumulatedMonth.getMonth() + 1
      );
      month <= currentDate;
      month = new Date(month.getFullYear(), month.getMonth() + 1)
    ) {
      const repaidAmount =
        repaidAmountsByCollectMonth[month]?.repaidAmount || 0;
      const repaidProjects = repaidAmountsByCollectMonth[month]?.projects || [];
      //console.log('debug - repaidProjects: ', repaidProjects);
      const investedAmount =
        lastInvestedAmount + (cumulatedByMonth[month]?.investedAmount || 0);
      //console.log('debug - lastInvestedAmount: ', lastInvestedAmount, ' pour le mois ', month);
      //console.log('debug - cumulatedByMonth[month]?.investedAmount: ', cumulatedByMonth[month]?.investedAmount, ' pour le mois ', month);
      //console.log('debug - investedAmount: ', investedAmount, ' pour le mois ', month );

      cumulatedByMonth[month] = {
        investedAmount: investedAmount - repaidAmount,
        projects: [
          ...(cumulatedByMonth[month]?.projects || []),
          ...repaidProjects.map(
            (project) =>
              "\nRemboursement :  " +
              project.replace(/^\s*\n/, "").replace(/\((.*)\)/g, "(-$1)")
          ),
        ],
      };
      //console.log('debug - cumulatedByMonth[month].investedAmount: ', cumulatedByMonth[month].investedAmount);

      lastInvestedAmount = cumulatedByMonth[month].investedAmount;
    }

    // Récupération des labels et des données
    const labels = Object.keys(cumulatedByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => cumulatedByMonth[label].investedAmount);
    const projects = labels.map((label) => cumulatedByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };

  const cumulatedPrincipalByMonth = cumulatedAmount(
    allLoans,
    null,
    null,
    sumRepaidAmountByMonth(allLoans, null, 5)
  );

  // Intérêts cumulés
  const cumulatedInterests = (
    loans,
    platformId = null,
    statusId = null,
    repaid
  ) => {
    const cumulatedByMonth = {};

    // Initialisation de la variable lastCumulated à 0
    let lastCumulated = 0;

    loans.forEach((loan) => {
      if (
        (platformId === null || loan.platform_id === platformId) &&
        (statusId === null || loan.status === statusId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!cumulatedByMonth[collectMonthYear]) {
          cumulatedByMonth[collectMonthYear] = {
            interestsPaid: 0,
            projects: [],
          };
        }

        cumulatedByMonth[collectMonthYear].interestsPaid +=
          loan.interests_received;
        cumulatedByMonth[collectMonthYear].projects.push(
          "\n" + loan.project_name + " (" + loan.interests_received + "€)"
        );
      }
    });

    Object.keys(cumulatedByMonth).forEach((key) => {
      cumulatedByMonth[key].investedAmount =
        lastCumulated + cumulatedByMonth[key].investedAmount;

      lastCumulated = cumulatedByMonth[key].investedAmount;
    });

    // Récupération des labels et des données
    const labels = Object.keys(cumulatedByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => cumulatedByMonth[label].investedAmount);
    const projects = labels.map((label) => cumulatedByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };

  const averageInvestedAmountPerProject = (
    loans,
    statusId = null,
    platformId = null
  ) => {
    let totalInvestedAmount = 0;
    let projectCount = 0;

    loans.forEach((loan) => {
      if (
        (statusId === null || loan.status === statusId) &&
        (platformId === null || loan.platform_id === platformId)
      ) {
        totalInvestedAmount += loan.invested_amount;
        projectCount++;
      }
    });

    return (projectCount > 0 ? totalInvestedAmount / projectCount : 0).toFixed(
      0
    );
  };

  const averageInvestedAmountAll = averageInvestedAmountPerProject(allLoans);

  const averageInvestedAmountPerProjectByMonth = (
    loans,
    statusId = null,
    platformId = null
  ) => {
    const investmentsByMonth = {};

    loans.forEach((loan) => {
      if (
        (statusId === null || loan.status === statusId) &&
        (platformId === null || loan.platform_id === platformId)
      ) {
        const collectDate = new Date(loan.collect_date);
        const collectMonthYear = new Date(
          collectDate.getFullYear(),
          collectDate.getMonth()
        );

        if (!investmentsByMonth[collectMonthYear]) {
          investmentsByMonth[collectMonthYear] = {
            totalInvestedAmount: 0,
            projectCount: 0,
            projects: [],
          };
        }

        investmentsByMonth[collectMonthYear].totalInvestedAmount +=
          loan.invested_amount;
        investmentsByMonth[collectMonthYear].projectCount++;
        investmentsByMonth[collectMonthYear].projects.push(
          `\n${loan.project_name} (${loan.invested_amount}€)`
        );
      }
    });

    const labels = Object.keys(investmentsByMonth)
      .sort()
      .map((label) => new Date(label));
    const data = labels.map((label) => {
      const { totalInvestedAmount, projectCount } = investmentsByMonth[label];
      return projectCount > 0 ? totalInvestedAmount / projectCount : 0;
    });
    const projects = labels.map((label) => investmentsByMonth[label].projects);

    return {
      labels,
      data,
      projects,
    };
  };
  const avgInvestedAmountPerProjectByMonth =
    averageInvestedAmountPerProjectByMonth(allLoans);

  

  // Répartition des projets par type de projet (MDB, Promotion...)
  const projectTypeDistribution = (
    loans,
    statusId = null,
    platformId = null
  ) => {
    const distribution = {
      count: {},
      amount: {},
      percentage_count: {},
      percentage_amount: {},
    };

    let totalCount = 0;
    let totalAmount = 0;

    loans.forEach((loan) => {
      if (
        (statusId === null || loan.status === statusId) &&
        (platformId === null || loan.platform_id === platformId)
      ) {
        if (loan.type == undefined) {
          loan.type = "Inconnu";
        }
        // Si le type n'est pas encore dans l'objet distribution.count, initialisez-le avec la valeur 1
        if (!distribution.count.hasOwnProperty(loan.type)) {
          distribution.count[loan.type] = 1;
        } else {
          // Sinon, incrémentez le compteur pour le type donné
          distribution.count[loan.type]++;
        }

        // Si le type n'est pas encore dans l'objet distribution.amount, initialisez-le avec la valeur du montant investi
        if (!distribution.amount.hasOwnProperty(loan.type)) {
          distribution.amount[loan.type] = loan.invested_amount;
        } else {
          // Sinon, ajoutez le montant investi au total pour le type donné
          distribution.amount[loan.type] += loan.invested_amount;
        }

        // Incrémentez le total général pour le calcul du pourcentage
        totalCount++;
        totalAmount += loan.invested_amount;
      }
    });

    // Calculez les pourcentages pour chaque type
    Object.keys(distribution.count).forEach((type) => {
      distribution.percentage_count[type] = (
        (distribution.count[type] / totalCount) *
        100
      ).toFixed(1);
      distribution.percentage_amount[type] = (
        (distribution.amount[type] / totalAmount) *
        100
      ).toFixed(1);
    });

    return distribution;
  };

  // Distribution tout statut confondus
  const loansTypesDistribution = projectTypeDistribution(allLoans, null, null);
  const ongoingProjects = projectTypeDistribution(allLoans, 1);
  const completedProjects = projectTypeDistribution(allLoans, 5);

  console.log("completed: ", loansTypesDistribution.percentage_amount);

  // Distribution tout statut confondus

  //console.log(loansTypesDistribution);

  // Exemple de résultat: { "type1": 10, "type2": 5, "type3": 3 }

  // ******* ******* ******* ******* ******* ******* ******* ******* ******* *******
  // GRAPHIQUES ******* ******* ******* ******* ******* ******* ******* *******
  // ******* ******* ******* ******* ******* ******* ******* ******* ******* *******

  return (
    <div >
      

      <div className="globalInfo">
        <div className="card">
          {allLoans && (
            <div>
              <h2>
                Investissements cumulés :{" "}
                {allLoans.reduce((sum, loan) => sum + loan.invested_amount, 0)}{" "}
                €
              </h2>
              <h3>
                En cours :{" "}
                {allLoans
                  .filter((loan) => loan.status !== 5)
                  .reduce((sum, loan) => sum + loan.invested_amount, 0)}{" "}
                €
              </h3>
              <h3>
                Moyenne par projet :{" "}
                {(
                  allLoans.reduce(
                    (sum, loan) => sum + loan.invested_amount,
                    0
                  ) / allLoans.length
                ).toFixed(0)}{" "}
                €
              </h3>
            </div>
          )}
        </div>
        <div className="card">
          {weightedAvgRate && (
            <h2>Rendement global moyen : {weightedAvgRate} %</h2>
          )}
          {weightedAvgRate && (
            <h3>Projets en cours : {weightedAvgRateInProgress} %</h3>
          )}
          {weightedAvgRate && (
            <h3>Projets remboursés : {weightedAvgRateRepaid} %</h3>
          )}
        </div>
        <div className="card">
          {allLoans && (
            <h2>
              Projets en cours :{" "}
              {allLoans.filter((loan) => loan.status !== 5).length}
            </h2>
          )}
          {allLoans && (
            <h3>
              Projets en retard :{" "}
              {allLoans.filter((loan) => loan.status === 2).length}
            </h3>
          )}

          {allLoans && (
            <h3>
              Projets en défaut :{" "}
              {allLoans.filter((loan) => loan.status === 3).length}
            </h3>
          )}
        </div>
        <div className="card">
          {avgProjectDuration && (
            <h2>Durée globale projets : {avgProjectDuration} mois</h2>
          )}
          {avgProjectDuration && (
            <h3>
              Durée moyenne projets en cours : {avgInProgressProjectDuration}{" "}
              mois
            </h3>
          )}
          {avgProjectDuration && (
            <h3>
              Durée moyenne projets remboursés : {avgRepaidProjectDuration} mois
            </h3>
          )}
        </div>
      </div>
      <div className="statsGlobalMenu">
        <div className="statsSubMenu" onClick={() => setActiveTab("tab1")}>
          Projets
        </div>
        <div className="statsSubMenu" onClick={() => setActiveTab("tab7")}>
          Finance
        </div>
        <div className="statsSubMenu" onClick={() => setActiveTab("tab2")}>
          Durées
        </div>
        <div
          className="statsSubMenu"
          onClick={() => setActiveTab("tabPlatforms")}
        >
          Plateformes
        </div>
        <div className="statsSubMenu" onClick={() => setActiveTab("tab5")}>
          Stats Customs
        </div>
      </div>

      {/*weightedAvgRate && <h3>Taux de rendement global : {weightedAvgRate} %</h3>*/}
      {/*weightedAvgRate && <h3>Taux de rendement projets en cours : {weightedAvgRateInProgress} %</h3>*/}
      {/*weightedAvgRate && <h3>Taux de rendement projets remboursés : {weightedAvgRateRepaid} %</h3>*/}

      {/*avgProjectDuration && <h3>Durée globale projets : {avgProjectDuration} mois</h3>*/}
      {/*avgProjectDuration && <h3>Durée moyenne projets en cours : {avgInProgressProjectDuration} mois</h3>*/}
      {/*avgProjectDuration && <h3>Durée moyenne projets remboursés : {avgRepaidProjectDuration} mois</h3>*/}

      {activeTab === "tab1" && (
        <div>
          {allLoans && (
            <div className="grid-infos">
              <div className="grid-infos-item">
                Projets réalisés : {allLoans.length}
                <br />
                Projets en cours :{" "}
                {allLoans.filter((loan) => loan.status !== 5).length}
              </div>

              <div className="grid-infos-item">
                Retards officiels :{" "}
                {allLoans.filter((loan) => loan.status === 2).length} (
                {(100 * allLoans.filter((loan) => loan.status === 2).length) /
                  allLoans.length}
                %)
                <br />
                Retards détectés : {arrayLateLoans.length} (
                {((100 * arrayLateLoans.length) / allLoans.length).toFixed(1)}
                %)
              </div>

              <div className="grid-infos-item">
                Défauts officiels :{" "}
                {allLoans.filter((loan) => loan.status === 3).length} (
                {(100 * allLoans.filter((loan) => loan.status === 3).length) /
                  allLoans.length}
                %)
              </div>
            </div>
          )}

          <div className="grid">
            <div className="grid-item">
              {projectsByCollectMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: projectsByCollectMonthData.labels,
                      datasets: [
                        {
                          label: "Nombre de projets",
                          data: projectsByCollectMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${projectsByCollectMonthData.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Nombre de projets : ${value}`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${projectsByCollectMonthData.projects[
                                index
                              ].join(", ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Nombre de projets financés par mois de collecte",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            <div className="grid-item">
              {repaidProjectsByMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: repaidProjectsByMonthData.labels,
                      datasets: [
                        {
                          label: "Nombre de projets",
                          data: repaidProjectsByMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${repaidProjectsByMonthData.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Nombre de projets : ${value}`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${repaidProjectsByMonthData.projects[
                                index
                              ].join(", ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Nombre de projets remboursés chaque mois",
                          font: {
                            size: 20,
                          },
                        },
                        subtitle: {
                          display: true,
                          text: "(mois de remboursement estimée)",
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            <div className="grid-item">
              {futureRepaidProjectsByMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: futureRepaidProjectsByMonthData.labels,
                      datasets: [
                        {
                          label: "Nombre de projets",
                          data: futureRepaidProjectsByMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${futureRepaidProjectsByMonthData.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Nombre de projets : ${value}`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${futureRepaidProjectsByMonthData.projects[
                                index
                              ].join(", ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Estimation du nombre de projets restant à rembourser",
                          font: {
                            size: 20,
                          },
                        },
                        subtitle: {
                          display: true,
                          text: "(mois de remboursement estimée)",
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            {loansTypesDistribution && (
              <div className="grid-item">
                <div className="chart-container">
                  <Doughnut
                    data={{
                      labels: Object.keys(loansTypesDistribution.count),
                      datasets: [
                        {
                          label: "Nombre de projets",
                          data: Object.values(loansTypesDistribution.count),
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            // Ajoutez d'autres couleurs si nécessaire
                          ],
                          borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(255, 206, 86, 1)",
                            // Ajoutez d'autres couleurs si nécessaire
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        percentagePlugin: false,
                        title: {
                          display: true,
                          text: "Répartition des projets par type",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "right",
                          display: true,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              </div>
            )}

            {loansTypesDistribution && (
              <div className="grid-item">
                <div className="chart-container">
                  <Doughnut
                    data={{
                      labels: Object.keys(loansTypesDistribution.amount),
                      datasets: [
                        {
                          label: "Montant investi ",
                          data: Object.values(loansTypesDistribution.amount),
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            // Ajoutez d'autres couleurs si nécessaire
                          ],
                          borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(255, 206, 86, 1)",
                            // Ajoutez d'autres couleurs si nécessaire
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        percentagePlugin: false,
                        title: {
                          display: true,
                          text: "Répartition des projets par type d'opérations",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "right",
                          display: true,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              </div>
            )}

            {loansTypesDistribution && (
              <div className="grid-item">
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: ["Global", "En cours", "Terminés"],
                      datasets: Object.keys(
                        loansTypesDistribution.percentage_amount
                      ).map((type, index) => {
                        const colors = [
                          "rgba(93, 165, 218, 0.6)", // Bleu
                          "rgba(250, 164, 58, 0.6)", // Orange
                          "rgba(96, 189, 104, 0.6)", // Vert
                          "rgba(240, 85, 85, 0.6)", // Rouge
                          "rgba(230, 160, 215, 0.6)", // Rose
                          "rgba(255, 224, 130, 0.6)", // Jaune
                          "rgba(75, 122, 180, 0.6)", // Bleu foncé
                          "rgba(123, 201, 111, 0.6)", // Vert clair
                          "rgba(250, 120, 150, 0.6)", // Rose clair
                          "rgba(50, 205, 224, 0.6)", // Cyan
                        ];
                        const borderColors = [
                          "rgba(93, 165, 218, 1)", // Bleu
                          "rgba(250, 164, 58, 1)", // Orange
                          "rgba(96, 189, 104, 1)", // Vert
                          "rgba(240, 85, 85, 1)", // Rouge
                          "rgba(230, 160, 215, 1)", // Rose
                          "rgba(255, 224, 130, 1)", // Jaune
                          "rgba(75, 122, 180, 1)", // Bleu foncé
                          "rgba(123, 201, 111, 1)", // Vert clair
                          "rgba(250, 120, 150, 1)", // Rose clair
                          "rgba(50, 205, 224, 1)", // Cyan
                        ];

                        const globalData =
                          loansTypesDistribution.percentage_amount[type];
                        const ongoingData =
                          ongoingProjects.percentage_amount[type];
                        const completedData =
                          completedProjects.percentage_amount[type];

                        return {
                          label: type,
                          data: [globalData, ongoingData, completedData],
                          backgroundColor: colors[index % colors.length],
                          borderColor:
                            borderColors[index % borderColors.length],
                          borderWidth: 1,
                        };
                      }),
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                          beginAtZero: true,
                          ticks: {
                            // Affiche les pourcentages sur l'axe des ordonnées
                            callback: (value) => `${value}%`,
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        legend: {
                          position: "left",
                        },
                        title: {
                          display:true,
                          text: "% montants investis par type d'opérations",
                          font: {
                            size: 20,
                          },                        
                        }
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              </div>
            )}

{loansTypesDistribution && (
              <div className="grid-item">
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: ["Global", "En cours", "Terminés"],
                      datasets: Object.keys(
                        loansTypesDistribution.percentage_count
                      ).map((type, index) => {
                        const colors = [
                          "rgba(93, 165, 218, 0.6)", // Bleu
                          "rgba(250, 164, 58, 0.6)", // Orange
                          "rgba(96, 189, 104, 0.6)", // Vert
                          "rgba(240, 85, 85, 0.6)", // Rouge
                          "rgba(230, 160, 215, 0.6)", // Rose
                          "rgba(255, 224, 130, 0.6)", // Jaune
                          "rgba(75, 122, 180, 0.6)", // Bleu foncé
                          "rgba(123, 201, 111, 0.6)", // Vert clair
                          "rgba(250, 120, 150, 0.6)", // Rose clair
                          "rgba(50, 205, 224, 0.6)", // Cyan
                        ];
                        const borderColors = [
                          "rgba(93, 165, 218, 1)", // Bleu
                          "rgba(250, 164, 58, 1)", // Orange
                          "rgba(96, 189, 104, 1)", // Vert
                          "rgba(240, 85, 85, 1)", // Rouge
                          "rgba(230, 160, 215, 1)", // Rose
                          "rgba(255, 224, 130, 1)", // Jaune
                          "rgba(75, 122, 180, 1)", // Bleu foncé
                          "rgba(123, 201, 111, 1)", // Vert clair
                          "rgba(250, 120, 150, 1)", // Rose clair
                          "rgba(50, 205, 224, 1)", // Cyan
                        ];

                        const globalData =
                          loansTypesDistribution.percentage_count[type];
                        const ongoingData =
                          ongoingProjects.percentage_count[type];
                        const completedData =
                          completedProjects.percentage_count[type];

                        return {
                          label: type,
                          data: [globalData, ongoingData, completedData],
                          backgroundColor: colors[index % colors.length],
                          borderColor:
                            borderColors[index % borderColors.length],
                          borderWidth: 1,
                        };
                      }),
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                          beginAtZero: true,
                          ticks: {
                            // Affiche les pourcentages sur l'axe des ordonnées
                            callback: (value) => `${value}%`,
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        legend: {
                          position: "left",
                        },
                        title: {
                          display:true,
                          text: "Répartition projets par type d'opérations",
                          font: {
                            size: 20,
                          },                        
                        },
                        subtitle: {
                          display:true,
                          text: "(en %)",
                          
                        },

                      },

                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              </div>
            )}



          </div>

          {arrayLateLoans.length != 0 && (
            <div>
              <div className="sectionTitle">
                Projets en retard ou supposés en retard
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Nom du projet</th>
                    <th>Statut officiel du projet</th>
                    <th>Date de collecte</th>
                    <th>Date estimée du remboursement</th>
                    <th>Taux du projet</th>
                    <th>Montant prêté</th>
                    <th>Capital restant à rembourser</th>
                    <th>Intérêts déjà versés</th>
                  </tr>
                </thead>
                <tbody>
                  {arrayLateLoans.map((project, index) => (
                    <tr key={index}>
                      <td>{project.projectName}</td>
                      <td>{getStatusFromId(project.status)}</td>
                      <td>{project.collectDate.toLocaleDateString()}</td>
                      <td>
                        {project.estimatedRepaymentDate.toLocaleDateString()}
                      </td>
                      <td>{project.projectRate}%</td>
                      <td>{project.loanAmount}</td>
                      <td>{project.remainingCapital}</td>
                      <td>{project.paidInterests}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/*numberBy && (
            <div className="chart-container">
              <Bar
                data={{
                  labels: numberBy.labels,
                  datasets: numberBy.datasets,
                }}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: function (context) {
                          const dataIndex = context[0].dataIndex;
                          const dataset = context[0].dataset;
                          const loanName = dataset.loanNames[dataIndex];
                          return loanName;
                        },
                        label: function (context) {
                          const dataset = context.dataset;
                          const value = context.parsed.y;
                          return `${dataset.label}: ${value}`;
                        },
                      },
                    },
                    title: {
                      display: true,
                      text: "Évolution de projets par statut au cours du temps",
                      font: {
                        size: 20,
                      },
                    },
                    subtitle: {
                      display: true,
                      text: "(par date de remboursement)",
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit: "month",
                        displayFormats: {
                          month: "MMM yyyy",
                        },
                      },
                      title: {
                        display: true,
                        text: "Mois",
                      },
                    },
                    y: {
                      stacked: true,
                      title: {
                        display: true,
                        text: "xxxxxxxr",
                      },
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
              )*/}

          {/*montantsData && projetsData && (
            <div>
              <div className="chart-container">
                <Bar
                  data={{
                    labels: projetsData.labels,
                    datasets: [
                      {
                        label: "Projets démarrés",
                        data: projetsData.started,
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1,
                        barPercentage: 1, // La valeur par défaut est 0.9, une valeur plus petite permet de superposer complètement les deux parties d'une barre.
                        stack: "combined",
                      },
                      {
                        label: "Projets terminés",
                        data: projetsData.finished,
                        backgroundColor: "rgba(255, 159, 64, 0.2)",
                        borderColor: "rgba(255, 159, 64, 1)",
                        borderWidth: 1,
                        barPercentage: 1, // La valeur par défaut est 0.9, une valeur plus petite permet de superposer complètement les deux parties d'une barre.
                        stack: "combined",
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        type: "time",
                        time: {
                          unit: "month",
                          displayFormats: {
                            month: "MMM yyyy",
                          },
                        },
                      },
                      y: {
                        type: "linear",
                        stacked: true,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "white",
                      },
                      title: {
                        display: true,
                        text: "Nombre de projets démarrés et terminés",
                        font: {
                          size: 20,
                        },
                      },
                      subtitle: {
                        display: false,
                        text: "(par mois))",
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  }}
                />
              </div>
            </div>
                )*/}
        </div>
      )}

      {activeTab === "tab2" && (
        <div className="grid">
          <div className="grid-item">
            {avgProjectsDuration && (
              <div className="chart-container">
                <Bar
                  data={{
                    labels: avgProjectsDuration.map(
                      (item) => `${item.month + 1}/${item.year}`
                    ),
                    datasets: [
                      {
                        label: "Projets remboursés",
                        data: avgProjectsDuration.map((item) =>
                          parseFloat(item.averageDurationRepaid)
                        ),
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                      },
                      {
                        label: "Projets encore en cours",
                        data: avgProjectsDuration.map((item) =>
                          parseFloat(item.averageDurationOther)
                        ),
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                    plugins: {
                      percentagePlugin: false,
                      tooltip: {
                        callbacks: {
                          footer: (tooltipItems) => {
                            const index = tooltipItems[0].dataIndex;
                            return [
                              `Projets remboursés : ${avgProjectsDuration[
                                index
                              ].repaidProjects.join(", ")}`,
                              `Autres projets : ${avgProjectsDuration[
                                index
                              ].otherProjects.join(", ")}`,
                            ];
                          },
                        },
                      },
                      legend: {
                        position: "bottom",
                        display: true,
                      },
                      title: {
                        display: true,
                        text: "Durée moyenne des projets",
                        font: {
                          size: 20,
                        },
                      },
                      subtitle: {
                        display: true,
                        text: "(selon mois de collecte)",
                      },
                    },
                  }}
                  width={540} // Définir la largeur en pixels
                  height={400} // Définir la hauteur en pixels
                />
              </div>
            )}
          </div>
        </div>
      )}
      {/* Fin Tab2 */}

      {/* DEBUT - Stats Custom */}
      {activeTab === "tab5" && (
        <div className="customStatsSection">
          <hr />
          <div className="customStatsFiltersMenu">
            <div className="customStatsFiltersMenuItem">
              <h3>Plateforme</h3>
              <select value={selectedPlatform} onChange={handlePlatformChange}>
                <option value="">Toutes</option>
                <option value="EstateGuru">EstateGuru</option>
                <option value="La Première Brique">La Première Brique</option>
              </select>
            </div>
            <div className="customStatsFiltersMenuItem">
              <h3>Statut</h3>
              <select value={selectedStatus} onChange={handleStatusChange}>
                <option value="">Tous</option>
                <option value="1">En cours</option>
                <option value="2">Retard</option>
                <option value="3">Défaut</option>
                <option value="4">En attente</option>
                <option value="5">Remboursé</option>
              </select>
            </div>
            {allLoans && (
              <div className="projectsListOnOff">
                <button className="tabButton" onClick={handleToggleTable}>
                  {showTable ? "Masquer le tableau" : "Afficher le tableau"}
                </button>
              </div>
            )}
          </div>

          <div className="customStatsResultsCounter">
            {" "}
            Résultats : ({filteredLoans.length})
          </div>

          <hr />

          {allLoans && (
            <div>
              {showTable && (
                <div>
                  <table {...getTableProps()} className="loans-table">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="sectionTitle">Projets</div>
              <div className="grid">
                {projectsByCollectMonthDataCustom && (
                  <div className="grid-item">
                    <div className="chart-container">
                      <Bar
                        data={{
                          labels: projectsByCollectMonthDataCustom.labels,
                          datasets: [
                            {
                              label: "Nombre de projets",
                              data: projectsByCollectMonthDataCustom.data,
                              backgroundColor: "rgba(75, 192, 192, 0.2)",
                              borderColor: "rgba(75, 192, 192, 1)",
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                            },
                            x: {
                              type: "time",
                              time: {
                                unit: "month",
                                displayFormats: {
                                  month: "MMM yyyy",
                                },
                              },
                            },
                          },
                          maintainAspectRatio: false,
                          plugins: {
                            percentagePlugin: false,
                            tooltip: {
                              callbacks: {
                                title: (tooltipItems) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return `Mois : ${projectsByCollectMonthDataCustom.labels[
                                    index
                                  ].toLocaleDateString("fr-FR", {
                                    month: "short",
                                    year: "numeric",
                                  })}`;
                                },
                                label: (tooltipItems) => {
                                  const value = tooltipItems.parsed.y;
                                  return `Nombre de projets : ${value}`;
                                },
                                footer: (tooltipItems) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return `Projets : ${projectsByCollectMonthDataCustom.projects[
                                    index
                                  ].join(", ")}`;
                                },
                              },
                            },
                            title: {
                              display: true,
                              text: "Nombre de projets financés",
                              font: {
                                size: 20,
                              },
                            },
                            subtitle: {
                              display: true,
                              text: "(par mois de collecte)",
                            },
                            legend: {
                              position: "bottom",
                              display: false,
                            },
                          },
                        }}
                        width={540} // Définir la largeur en pixels
                        height={400} // Définir la hauteur en pixels
                      />
                    </div>
                  </div>
                )}

                {repaidProjectsByMonthDataCustom && (
                  <div className="grid-item">
                    <div className="chart-container">
                      <Bar
                        data={{
                          labels: repaidProjectsByMonthDataCustom.labels,
                          datasets: [
                            {
                              label: "Nombre de projets",
                              data: repaidProjectsByMonthDataCustom.data,
                              backgroundColor: "rgba(75, 192, 192, 0.2)",
                              borderColor: "rgba(75, 192, 192, 1)",
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              beginAtZero: true,
                            },
                            x: {
                              type: "time",
                              time: {
                                unit: "month",
                                displayFormats: {
                                  month: "MMM yyyy",
                                },
                              },
                            },
                          },
                          plugins: {
                            percentagePlugin: false,
                            tooltip: {
                              callbacks: {
                                title: (tooltipItems) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return `Mois : ${repaidProjectsByMonthDataCustom.labels[
                                    index
                                  ].toLocaleDateString("fr-FR", {
                                    month: "short",
                                    year: "numeric",
                                  })}`;
                                },
                                label: (tooltipItems) => {
                                  const value = tooltipItems.parsed.y;
                                  return `Nombre de projets : ${value}`;
                                },
                                footer: (tooltipItems) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return `Projets : ${repaidProjectsByMonthDataCustom.projects[
                                    index
                                  ].join(", ")}`;
                                },
                              },
                            },
                            title: {
                              display: true,
                              text: "Nombre de projets remboursés",
                              font: {
                                size: 20,
                              },
                            },
                            subtitle: {
                              display: true,
                              text: "(par mois estimé de remboursement)",
                            },
                            legend: {
                              position: "bottom",
                              display: false,
                            },
                          },
                        }}
                        width={540} // Définir la largeur en pixels
                        height={400} // Définir la hauteur en pixels
                      />
                    </div>
                  </div>
                )}

                {platforms && (
                  <div className="grid-item">
                    <div className="chart-container">
                      <Bar
                        data={{
                          labels: platforms,

                          datasets: [
                            {
                              label: "Montant investi (€)",
                              data: amountsPlatforms,
                              unit: "€",
                              backgroundColor: [
                                "rgba(255, 99, 132, 0.2)",
                                "rgba(255, 159, 64, 0.2)",
                                "rgba(255, 205, 86, 0.2)",
                                "rgba(75, 192, 192, 0.2)",
                                "rgba(54, 162, 235, 0.2)",
                                "rgba(153, 102, 255, 0.2)",
                                "rgba(201, 203, 207, 0.2)",
                              ],
                              borderColor: [
                                "rgb(255, 99, 132)",
                                "rgb(255, 159, 64)",
                                "rgb(255, 205, 86)",
                                "rgb(75, 192, 192)",
                                "rgb(54, 162, 235)",
                                "rgb(153, 102, 255)",
                                "rgb(201, 203, 207)",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              type: "category",
                            },
                            y: {
                              type: "linear",
                            },
                          },
                          plugins: {
                            percentagePlugin: false,
                            datalabels: {
                              display: true,
                              color: "white",
                            },
                            title: {
                              display: true,
                              text: "Projets en cours",
                              font: {
                                size: 25,
                              },
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                        width={540} // Définir la largeur en pixels
                        height={400} // Définir la hauteur en pixels
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="sectionTitle">Finance</div>
          <div className="grid-item"></div>
        </div>
      )}
      {/* Fin tab5*/}

      {activeTab === "tabPlatforms" && (
        <div className="grid">
          {projectsByPlatform && (
            <div className="grid-item">
              <div className="chart-container">
                <Doughnut
                  data={{
                    labels: Object.keys(projectsByPlatform),
                    datasets: [
                      {
                        data: Object.values(projectsByPlatform).map(
                          (platformData) => {
                            const totalCount = Object.values(
                              platformData
                            ).reduce((a, b) => a + b, 0);
                            return totalCount;
                          }
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      percentagePlugin: false,
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Nombre de projets financés par plateforme",
                        font: {
                          size: 20,
                        },
                      },
                    },
                  }}
                  width={540} // Définir la largeur en pixels
                  height={400} // Définir la hauteur en pixels
                />
              </div>
            </div>
          )}

          {projectsByPlatform && (
            <div className="grid-item">
              <div className="chart-container">
                <Doughnut
                  data={{
                    labels: Object.keys(projectsByPlatform),
                    datasets: [
                      {
                        data: Object.values(projectsByPlatform).map(
                          (platformData) => {
                            const inProgressCount =
                              platformData["En cours"] || 0;
                            return inProgressCount;
                          }
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      percentagePlugin: false,
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Nombre de projets en cours par plateforme",
                        font: {
                          size: 20,
                        },
                      },
                    },
                  }}
                  width={540} // Définir la largeur en pixels
                  height={400} // Définir la hauteur en pixels
                />
              </div>
            </div>
          )}

          {amountsByPlatform && (
            <div className="grid-item">
              <div className="chart-container">
                <Doughnut
                  data={{
                    labels: Object.keys(amountsByPlatform),
                    datasets: [
                      {
                        data: Object.values(amountsByPlatform).map(
                          (platformData) => {
                            const totalCount = Object.values(
                              platformData
                            ).reduce((a, b) => a + b, 0);
                            return totalCount;
                          }
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      percentagePlugin: false,
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Montants globaux investis par plateforme",
                        font: {
                          size: 20,
                        },
                      },
                    },
                  }}
                  width={540} // Définir la largeur en pixels
                  height={400} // Définir la hauteur en pixels
                />
              </div>
            </div>
          )}
          {amountsByPlatform && (
            <div className="grid-item">
              <div className="chart-container">
                <Doughnut
                  data={{
                    labels: Object.keys(amountsByPlatform),
                    datasets: [
                      {
                        data: Object.values(amountsByPlatform).map(
                          (platformData) => {
                            const inProgressAmount =
                              platformData["En cours"] || 0;
                            return inProgressAmount;
                          }
                        ),
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                          "rgba(255, 206, 86, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      percentagePlugin: false,
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Montants actuellement investis par plateforme",
                        font: {
                          size: 20,
                        },
                      },
                    },
                  }}
                  width={540} // Définir la largeur en pixels
                  height={400} // Définir la hauteur en pixels
                />
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === "tab7" && (
        <div>
          {allLoans && (
            <div className="grid-infos">
              <div className="grid-infos-item">
                Retards officiels :{" "}
                {allLoans
                  .filter((loan) => loan.status === 2)
                  .reduce((sum, loan) => sum + loan.invested_amount, 0)}{" "}
                (
                {(
                  (100 *
                    allLoans
                      .filter((loan) => loan.status === 2)
                      .reduce((sum, loan) => sum + loan.invested_amount, 0)) /
                  allLoans
                    .filter((loan) => loan.status !== 5)
                    .reduce((sum, loan) => sum + loan.invested_amount, 0)
                ).toFixed(1)}
                %)
                <br />
                Retards détectés :{" "}
                {arrayLateLoans.reduce((sum, loan) => {
                  return sum + loan.remainingCapital;
                }, 0)}{" "}
                € (
                {(
                  (100 *
                    arrayLateLoans.reduce((sum, loan) => {
                      return sum + loan.remainingCapital;
                    }, 0)) /
                  allLoans
                    .filter((loan) => loan.status !== 5)
                    .reduce((sum, loan) => sum + loan.invested_amount, 0)
                ).toFixed(1)}
                %)
              </div>
            </div>
          )}

          <div className="sectionTitle">Investissements</div>
          <div className="grid">
            <div className="grid-item">
              {ccc && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: ccc.labels,
                      datasets: [
                        {
                          label: "Montant investi",
                          data: ccc.investedData.map((value) => -value),
                          backgroundColor: "rgba(255, 99, 132, 0.2)",
                          borderColor: "rgba(255, 99, 132, 1)",
                          borderWidth: 1,
                          stack: "combined",
                        },
                        {
                          label: "Montant remboursé",
                          data: ccc.repaidData,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                          stack: "combined",
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${ccc.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const datasetIndex = tooltipItems.datasetIndex;
                              const value = tooltipItems.parsed.y;
                              if (datasetIndex === 0) {
                                return `Montant total investi : ${value} €`;
                              } else {
                                return `Montant total remboursé : ${value} €`;
                              }
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              const datasetIndex = tooltipItems[0].datasetIndex;
                              if (datasetIndex === 0) {
                                return `Projets investis : ${ccc.investedProjects[
                                  index
                                ].join(" ")}`;
                              } else {
                                return `Projets remboursés : ${ccc.repaidProjects[
                                  index
                                ].join(" ")}`;
                              }
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Investissements et Remboursements",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            <div className="grid-item">
              {cumulatedPrincipalByMonth && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: cumulatedPrincipalByMonth.labels,
                      datasets: [
                        {
                          label: "Projets",
                          data: cumulatedPrincipalByMonth.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${cumulatedPrincipalByMonth.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Montant total investi : ${value} €`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${cumulatedPrincipalByMonth.projects[
                                index
                              ].join(" ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Capital investi cumulé",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>
            
            <div className="grid-item">
              {investmentsByCollectMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: investmentsByCollectMonthData.labels,
                      datasets: [
                        {
                          label: "Projets",
                          data: investmentsByCollectMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                          stack: "combined",
                        },
                        {
                          label: "Projets remboursés",
                          data: repaidAmountsByCollectMonthData.data,
                          backgroundColor: "rgba(62, 180, 137, 0.9)",
                          borderColor: "rgba(62, 180, 137, 1)",
                          borderWidth: 1,
                          stack: "combined",
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              const datasetIndex = tooltipItems[0].datasetIndex;
                              if (datasetIndex === 0) {
                                return `Mois : ${investmentsByCollectMonthData.labels[
                                  index
                                ].toLocaleDateString("fr-FR", {
                                  month: "short",
                                  year: "numeric",
                                })}`;
                              } else {
                                return `Mois : ${repaidAmountsByCollectMonthData.labels[
                                  index
                                ].toLocaleDateString("fr-FR", {
                                  month: "short",
                                  year: "numeric",
                                })}`;
                              }
                            },
                            label: (tooltipItems) => {
                              const datasetIndex = tooltipItems.datasetIndex;
                              const value = tooltipItems.parsed.y;
                              if (datasetIndex === 0) {
                                return `Montant investi : ${value} €`;
                              } else {
                                return `Montant remboursé : ${value} €`;
                              }
                            },
                            footer: (tooltipItems) => {
                              const datasetIndex = tooltipItems.datasetIndex;
                              const index = tooltipItems[0].dataIndex;
                              if (datasetIndex === 0) {
                                return `Projets : ${investmentsByCollectMonthData.projects[
                                  index
                                ].join(" ")}`;
                              } else {
                                //return `Projets : ${repaidAmountsByCollectMonthData.projects[index].join(" ")}`;
                                const projects =
                                  repaidAmountsByCollectMonthData.projects[
                                    index
                                  ];
                                return `Projets : ${
                                  projects ? projects.join(" ") : ""
                                }`;
                              }
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Investissements et remboursements",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            <div className="grid-item">
              {avgInvestedAmountPerProjectByMonth && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: avgInvestedAmountPerProjectByMonth.labels,
                      datasets: [
                        {
                          label: "Montant moyen investi par projet",
                          data: avgInvestedAmountPerProjectByMonth.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${avgInvestedAmountPerProjectByMonth.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Montant moyen investi par projet : ${value.toFixed(
                                2
                              )}€`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${avgInvestedAmountPerProjectByMonth.projects[
                                index
                              ].join(", ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Montant moyen investi par projet",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>
            <div className="grid-item">
              {labels && (
                <div>
                  <div className="chart-container">
                    <Bar
                      data={{
                        labels: rates,

                        datasets: [
                          {
                            label: "Montant investi (€)",
                            data: amountsRates,
                            unit: "€",
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 159, 64, 0.2)",
                              "rgba(255, 205, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(201, 203, 207, 0.2)",
                            ],
                            borderColor: [
                              "rgb(255, 99, 132)",
                              "rgb(255, 159, 64)",
                              "rgb(255, 205, 86)",
                              "rgb(75, 192, 192)",
                              "rgb(54, 162, 235)",
                              "rgb(153, 102, 255)",
                              "rgb(201, 203, 207)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            type: "category",
                          },
                          y: {
                            type: "linear",
                          },
                        },
                        plugins: {
                          percentagePlugin: false,
                          datalabels: {
                            display: true,
                            color: "white",
                          },
                          title: {
                            display: true,
                            text: "Montant investissements par taux de rendement",
                            font: {
                              size: 20,
                            },
                          },
                          legend: {
                            position: "bottom",
                            display: false,
                          },
                        },
                        responsive: true,
                      }}
                      width={540} // Définir la largeur en pixels
                      height={400} // Définir la hauteur en pixels
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="grid-item">
              {labels && (
                <div>
                  <div className="chart-container">
                    <Bar
                      data={{
                        labels: status,

                        datasets: [
                          {
                            label: "Montant investi (€)",
                            data: amountsStatus,
                            unit: "€",
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 159, 64, 0.2)",
                              "rgba(255, 205, 86, 0.2)",
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(153, 102, 255, 0.2)",
                              "rgba(201, 203, 207, 0.2)",
                            ],
                            borderColor: [
                              "rgb(255, 99, 132)",
                              "rgb(255, 159, 64)",
                              "rgb(255, 205, 86)",
                              "rgb(75, 192, 192)",
                              "rgb(54, 162, 235)",
                              "rgb(153, 102, 255)",
                              "rgb(201, 203, 207)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            type: "category",
                          },
                          y: {
                            type: "linear",
                          },
                        },
                        plugins: {
                          percentagePlugin: false,
                          datalabels: {
                            display: true,
                            color: "white",
                          },
                          title: {
                            display: true,
                            text: "Répartition investissements par statut",
                            font: {
                              size: 20,
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                      width={540} // Définir la largeur en pixels
                      height={400} // Définir la hauteur en pixels
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="sectionTitle">Remboursements</div>

          <div className="grid-infos">
            <div className="grid-infos-item">
              {weightedAvgRate && (
                <div>
                  <div>
                    Montants restant à percevoir :{" "}
                    {remainingAmount.total.toFixed(0)} €
                    <ul>
                      <li>
                        - de 1 mois : {remainingAmount.oneMonth.toFixed(0)} €
                      </li>
                      <li>
                        1 - 3 mois : {remainingAmount.threeMonths.toFixed(0)} €
                      </li>
                      <li>
                        3 - 6 mois : {remainingAmount.sixMonths.toFixed(0)} €
                      </li>
                      <li>
                        6 mois - 1 an : {remainingAmount.oneYear.toFixed(0)} €
                      </li>
                      <li>
                        1 an+ : {remainingAmount.moreOneYear.toFixed(0)} €
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid">
            <div className="grid-item">
              {repaidAmountsByCollectMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: repaidAmountsByCollectMonthData.labels,
                      datasets: [
                        {
                          label: "Projets",
                          data: repaidAmountsByCollectMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,

                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${repaidAmountsByCollectMonthData.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Montant remboursé : ${value} €`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${repaidAmountsByCollectMonthData.projects[
                                index
                              ].join(" ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Montants déjà remboursés / versés chaque mois",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>

            <div className="grid-item">
              {futureRepaidAmountsByMonthData && (
                <div className="chart-container">
                  <Bar
                    data={{
                      labels: futureRepaidAmountsByMonthData.labels,
                      datasets: [
                        {
                          label: "Projets",
                          data: futureRepaidAmountsByMonthData.data,
                          backgroundColor: "rgba(75, 192, 192, 0.2)",
                          borderColor: "rgba(75, 192, 192, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,

                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                        x: {
                          type: "time",
                          time: {
                            unit: "month",
                            displayFormats: {
                              month: "MMM yyyy",
                            },
                          },
                        },
                      },
                      plugins: {
                        percentagePlugin: false,
                        tooltip: {
                          callbacks: {
                            title: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Mois : ${futureRepaidAmountsByMonthData.labels[
                                index
                              ].toLocaleDateString("fr-FR", {
                                month: "short",
                                year: "numeric",
                              })}`;
                            },
                            label: (tooltipItems) => {
                              const value = tooltipItems.parsed.y;
                              return `Montant remboursé : ${value} €`;
                            },
                            footer: (tooltipItems) => {
                              const index = tooltipItems[0].dataIndex;
                              return `Projets : ${futureRepaidAmountsByMonthData.projects[
                                index
                              ].join(" ")}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: "Montants restant à rembourser",
                          font: {
                            size: 20,
                          },
                        },
                        legend: {
                          position: "bottom",
                          display: false,
                        },
                      },
                    }}
                    width={540} // Définir la largeur en pixels
                    height={400} // Définir la hauteur en pixels
                  />
                </div>
              )}
            </div>
          </div>
          <div className="sectionTitle">Liste des prêts en cours</div>
          {loansChronologicalTable && (
            <table className="loans-table">
              <thead>
                <tr>
                  <th>Plateforme</th>
                  <th>Projet</th>
                  <th>Date collecte</th>
                  <th>Date prévisionnelle de remboursement</th>
                  <th>Mois restants</th>
                  <th>Montant restant à percevoir</th>
                  <th>Capital déjà remboursé</th>
                  <th>Capital restant à rembourser</th>
                  <th>Intérêts déjà versés</th>
                  <th>Intérêts restants à verser</th>
                  <th>Taux d'intérêt</th>
                </tr>
              </thead>
              <tbody>
                {loansChronologicalTable.map((loan, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: getBackgroundColor(loan.remainingMonths),
                    }}
                  >
                    <td>{loan.platform}</td>
                    <td>{loan.projectName}</td>
                    <td>{loan.collectionDate.toISOString().slice(0, 10)}</td>
                    <td>{loan.repaymentDate.toISOString().slice(0, 10)}</td>
                    <td>{loan.remainingMonths}</td>
                    <td>{loan.remainingTotal.toFixed(2)}</td>
                    <td>{loan.principalRepaid.toFixed(2)}</td>
                    <td>{loan.remainingPrincipal.toFixed(2)}</td>
                    <td>{loan.interestsReceived.toFixed(2)}</td>
                    <td>{loan.remainingInterests.toFixed(2)}</td>
                    <td>{loan.interestRate.toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {receivedBy && (
            <div className="chart-container">
              <Bar
                data={{
                  labels: receivedBy.labels,
                  datasets: receivedBy.datasets,
                }}
                options={{
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit: "month",
                        displayFormats: {
                          month: "MMM yyyy",
                        },
                      },
                      title: {
                        display: true,
                        text: "Mois",
                      },
                    },
                    y: {
                      stacked: true,
                      title: {
                        display: true,
                        text: "Montant reçu ou à recevoir",
                      },
                    },
                  },
                  plugins: {
                    percentagePlugin: false,
                    legend: {
                      position: "bottom",
                    },
                    title: {
                      display: true,
                      text: "Montants perçus ou à percevoir",
                      font: {
                        size: 25,
                      },
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }}
                width={540} // Définir la largeur en pixels
                height={400} // Définir la hauteur en pixels
              />
            </div>
          )}

          <div className="chart-container">
            <Bar
              data={{
                labels: labels,

                datasets: [
                  {
                    label: "Capital remboursé (€)",
                    data: principalData,
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 1,
                    stack: "combined",
                  },
                  {
                    label: "Intérêts versés (€)",
                    data: interestData,
                    backgroundColor: "rgba(255, 159, 64, 0.2)",
                    borderColor: "rgba(255, 159, 64, 1)",
                    borderWidth: 1,
                    stack: "combined",
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "month",
                      displayFormats: {
                        month: "MMM yyyy",
                      },
                    },
                  },
                  y: {
                    type: "linear",
                  },
                },
                plugins: {
                  percentagePlugin: false,
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "Montants à percevoir",
                    font: {
                      size: 25,
                    },
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              }}
              width={540} // Définir la largeur en pixels
              height={400} // Définir la hauteur en pixels
            />
          </div>
        </div>
      )}
    </div>
  );
};

const getStatusFromId = (status_id) => {
  switch (status_id) {
    case 1:
      return "En cours";
    case 2:
      return "Retard";
    case 3:
      return "Défaut";
    case 4:
      return "En attente";
    case 5:
      return "Remboursé";
    default:
      console.error(`Statut inconnu: ${status_id}`);
      return null;
  }
};
/*
function getStatusFromId(statusId) {
  const statusMap = {
    1: "En cours",
    2: "Retard",
    3: "Défaut",
    4: "En attente",
    5: "Remboursé",
  };
  console.log('StatusMap[statusId] = ', statusMap[statusId])
  return statusMap[statusId];
}
*/

const getPlatformFromId = (platform_id) => {
  switch (platform_id) {
    case 1:
      return "EstateGuru";
    case 2:
      return "La Première Brique";
    default:
      console.error(`Statut inconnu: ${platform_id}`);
      return "Inconnue";
  }
};

const getPlatforms = () => {
  return {
    1: "EstateGuru",
    2: "La Première Brique",
    3: "Crowdestate",
  };
};

export default StatsPage;
