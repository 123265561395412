import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { parse, format, isValid } from "date-fns";
import "./Data.css";
import { useAuth } from "./App";
import { useNavigate } from "react-router-dom";

const UploadLPB = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = async (event) => {
        const buffer = event.target.result;
        const wb = XLSX.read(buffer, { type: "binary" });
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, range: 2 });
        const loans = data.map((loan) => ({
          PlatformID: 2,
          ProjectName: loan[1],
          CollectDate: formatDate(loan[0]),
          Status: getStatusId(loan[2]),
          InvestedAmount: loan[3],
          Currency: "Euro",
          InterestsRate: loan[4],
          SignatureDate: formatDate(loan[5]),
          MinMaturityDate: formatDate(loan[6]),
          MaxMaturityDate: formatDate(loan[7]),
          InterestsReceived: loan[10],
          PrincipalRepaid: loan[9],
          PenaltiesReceived: 0,
          BonusReceived: 0,
          Country: "France",
          Bonus: 0,
        }));


        // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/data/loans/all`, {
        //const response = await axios.post("http://localhost:3001/lpbloans", {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/data/storelpbloans`, {
          loans,
          user_id: user.id,
        });
        // Attente de la fin de la requête avant la redirection
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log(response.data);
        navigate('/');
      };
      fileReader.onloadend = () => {
        console.log("Fichier entièrement chargé.");
      };
    } catch (error) {
      console.error("Erreur lors de l'upload du fichier", error);
    }
  };

  const formatDate = (dateStr) => {
    const parsedDate = parse(dateStr, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, "yyyy-MM-dd");
    } else {
      console.error(`Invalid date: ${dateStr}`);
      return null;
    }
  };

  const getStatusId = (status) => {
    switch (status) {
      case "Finalisée":
        return 1;
      case "Retard":
        return 2;
      case "Défaut":
        return 3;
      case "En attente":
        return 4;
      case "Remboursée":
        return 5;
      default:
        console.error(`Statut inconnu: ${status}`);
        return null;
    }
  };

  return (
    <div className="DataPage">
      <hr />
      <h2>La Première Brique</h2>
      <h4>
        (Attention : les précédentes données seront détruites avant import)
      </h4>
      <div>
        <input type="file" onChange={handleFileInputChange} />
        <button type="button" onClick={handleFileUpload}>
          Uploader
        </button>
      </div>
      <hr />
      <h2>Plateforme B</h2>
      <p>...</p>
      <hr />
      <h2>Plateforme C</h2>
      <p>...</p>
    </div>
  );
};

export default UploadLPB;
