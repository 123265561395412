import React from 'react';
import "./VosStatsPage.css";


  

function VosStatsPage() {
  return (
    <div className="page-container">
      
      <div className="vosstatsPage">
          <h1>L'accès à vos propres statistiques est en cours de développement</h1>
          <h2>Un aperçu de à quoi cela ressemblera</h2>
        <p>Voici ci-dessous un aperçu de ce à quoi ressembleront vos statistiques personnels issus de vos propres investissements sur les plateformes de Crowdfunding.</p>
        
        <div className="presContainer">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/YSlsQn7YI28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>


        <p>Si vous voulez être tenu informé de la disponibilité de l'outil, laissez moi votre mail ci-dessous.</p>

        <p><div dangerouslySetInnerHTML={{ __html: `
          <!-- Begin Mailchimp Signup Form -->
          <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
          <style type="text/css">
              #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px; background-color:transparent;}
              /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
          </style>
          <div id="mc_embed_signup">
          <form action="https://immozaik.us16.list-manage.com/subscribe/post?u=a5304da0be726e68a6db014fc&amp;id=eb4d95f9a8&amp;f_id=003e7fe0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
          <div id="mc_embed_signup_scroll">
                  
          <div class="mc-field-group">
              <label for="mce-EMAIL">Email : </label>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
          </div>
          <div class="mc-field-group">
              <label for="mce-FNAME">Prénom </label>
              <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
          </div>
          <div hidden="true"><input type="hidden" name="tags" value="3715177"></div>
              <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
              </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_a5304da0be726e68a6db014fc_eb4d95f9a8" tabindex="-1" value=""></div>
              <div class="clear"><input type="submit" value="Me tenir informé" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
              </div>
          </form>
          </div>
          
          <!--End mc_embed_signup-->
        ` }} />
        </p>

      

          <h2>Plateformes intégrées</h2>
          <p>Pour l'instant, seuls les projets de la plateforme française <b><a href="https://lapremierebrique.fr/" target="_blank">La Première Brique</a></b> sont intégrés.<br /><br />
          D'autres vont suivre très prochainement.<br /><br />
          Voter pour les plateformes que vous aimeriez voir intégrer rapidement => <a href="https://docs.google.com/forms/d/e/1FAIpQLSfQI98ppOepxZKzdkxomkLaM7MNocZttlR9nricjTlQHLEOcw/viewform?usp=sf_link">Formulaire de vote</a></p>
        <p>Si vous souhaitez entrer en contact avec moi, vous pouvez également le faire à l'adresse  <a href="mailto:alex@immozaik.com?subject=ImmoZaïk">alex@immozaik.com</a><br /><br />
        Merci par avance pour vos feedbacks ! ;-)</p>
      </div>
    </div>
  );
}

export default VosStatsPage;
