import { useAuth } from "./App";
import React, { useEffect, useState } from 'react';
//import styles from "./StatsPage.css";

function AdminPage() {
    const [lpbProjects, setLpbProjects] = useState([]);
    const auth = useAuth();

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/loans/allLPBprojects`);
            const data = await response.json();  
            //console.log('data = ',data);   

                        // Tri des projets LPB par ordre décroissant chronologiquement pour la colonne date_collecte
                        const sortedLpbProjects = data.sort((a, b) => {
                            return new Date(b.date_collecte).getTime() - new Date(a.date_collecte).getTime();
                          });
            setLpbProjects(data);
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }, []);

      async function importData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/import`);
         
          const data = await response.text();
          console.log(data);
        } catch (error) {
          console.error("Error importing data:", error);
        }
      }
  
    return (
      <div>
        {auth.isAuthenticated ? (
        <div>
          <h1>Bienvenue sur la page privée !</h1>
          <button onClick={importData}>Importer les données LPB</button>
          <h1>Liste des projets LPB ({lpbProjects.length})</h1>
          <table>
  <thead>
    <tr>
      <th>id</th>
      <th>nom</th>
      <th>date_collecte</th>
      <th>montant_collecte</th>
      <th>investisseurs</th>
      <th>duree</th>
      <th>taux</th>
      <th>departement</th>
      <th>ville</th>
      <th>type</th>
      <th>id_lpb</th>
      <th>statut</th>
      <th>date_remboursement</th>
    </tr>
  </thead>
  <tbody>
    {lpbProjects.map((project) => (
      <tr key={project.id}>
        <td>{project.id}</td>
        <td>
        <a
          href={`https://www.lapremierebrique.fr/fr/projects/${project.id_lpb}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {project.nom}
        </a>
      </td>
        <td>{project.date_collecte ? new Date(project.date_collecte).toLocaleDateString() : ""}</td>
        <td>{project.montant_collecte ? project.montant_collecte.toLocaleString('fr-FR') + ' €' : '-'}</td>
        <td>{project.investisseurs}</td>
        <td>{project.duree}</td>
        <td>{project.taux}</td>
        <td>{project.departement}</td>
        <td>{project.ville}</td>
        <td>{project.type}</td>
        <td>{project.id_lpb}</td>
        <td>{project.statut}</td>
        <td>{project.date_remboursement ? new Date(project.date_remboursement).toLocaleDateString("fr-FR") : "-"}</td>
      </tr>
    ))}
  </tbody>
</table>
      </div>


          
          
        ) : (
          <p>Veuillez vous connecter pour accéder à cette page.</p>
        )}
        
      </div>
    );
  }


  export default AdminPage;
