import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie"; 
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";
import LeavePage from "./LeavePage";
import StatsPage from "./StatsPage";
import AboutPage from "./AboutPage";
//import ExcelUploader from "./ExcelUploader";
import UploadLPB from "./UploadLPB";
import LoginPage from "./LoginPage";
import AdminPage from "./AdminPage";
import VosStatsPage from "./VosStatsPage";
//import ProjectsList from "./pages/projects-list.tsx";
import StatsPlatforms from "./StatsPlatforms";
import Article1 from "./Article1";
import jwtDecode from "jwt-decode";

import "./App.css";

const AuthContext = createContext();

function useAuth() {
  return useContext(AuthContext);
}

function PrivateRoute({ children }) {
  //const token = localStorage.getItem("authToken");
  const token = Cookies.get("authToken"); // 3. Utilisation de Cookies.get

  //console.log("token: ", token);

  if (!token) {
    //console.log("token non défini");
    return <Navigate to="/statsplatforms" />;
  }

  try {
    jwtDecode(token); // Vérifie si le token est valide et non expiré
    //console.log("token valide");
    return children;
  } catch (error) {
    //console.log("token invalide");
    //localStorage.removeItem("authToken"); // Supprime le token invalide du stockage local
    Cookies.remove("authToken"); // Supprime le cookie du token invalide

    return <Navigate to="/statsplatforms" />;
  }
}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!Cookies.get("authToken") // 3. Utilisation de Cookies.get
  );

  
  useEffect(() => {
    const token = Cookies.get("authToken");
    if (token) {
      try {
        console.log('Use effect - appel');
        const decodedToken = jwtDecode(token);
        console.log('decodedToken.username = ',decodedToken.username);
        
        if (!decodedToken.sub) {
          console.log("Le token JWT ne contient pas de propriété sub.");
        } else {
          console.log("Le token JWT CONTIENT une propriété sub.");
        }
        //axios.get(`http://localhost:3002/users/${decodedToken.username}`)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${decodedToken.username}`)
          .then((response) => {
            const { username } = response.data;
            setUser({ token, username, id: decodedToken.id }); // Ajoute l'id à l'objet user stocké dans le state
     //       setUser({ token, username });
            setIsAuthenticated(true);
          })
          .catch(() => {
            setIsAuthenticated(false);
            Cookies.remove("authToken");
          });
      } catch (error) {
        setIsAuthenticated(false);
        Cookies.remove("authToken");
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);
  

  const login = async (username, password) => {
    try {
      console.log(
        "process.env.REACT_APP_API_BASE_URL = ",
        process.env.REACT_APP_API_BASE_URL
      );
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/data/auth`, {
        username,
        password,
      });
      //const token = response.data.token;
      const { token, id } = response.data;
      Cookies.set("authToken", token); 
      //setUser({ token, username });
      setUser({ token, username, id }); // Ajoute l'id à l'objet user stocké dans le state
      setIsAuthenticated(true);
    } catch (error) {
      console.log('Échec - username :', username, '  password : ', password);
      console.error("Échec de la connexion:", error);
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false); 
    //localStorage.removeItem('authToken');
    Cookies.remove("authToken"); // 3. Utilisation de Cookies.remove

  };

  const value = {
    user,
    isAuthenticated,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function App() {
  const auth = useAuth();
  /*
  console.log('NODE_ENV = ', process.env.NODE_ENV);
  console.log('ENV = ', process.env);
  */

  return (
      <Router>
        <div>
        <div>
          {/*<p>NODE_ENV : {process.env.NODE_ENV}</p>
          <p>URL de l'API : {process.env.REACT_APP_API_BASE_URL}</p>
          *}
          {/* Autre contenu de l'application */}
        </div>
          <div className="globalMenu">            
            <nav>
              <ul>
                  {/*  RETIRER PENDANT DÉVELOPPEMENT auth.isAuthenticated && (
                  <li className="globalMenuItem">
                    <Link to="/">Vos Stats</Link>
                  </li>
                  )*/} 
                  {auth.isAuthenticated && (
                  <li className="globalMenuItem">
                    <Link to="/uploadlpb">Vos Données</Link>
                  </li>
                  )}    

              <li className="globalMenuItem">
                <Link to="/statsplatforms">Stats Plateformes</Link>
              </li>              

              <li className="globalMenuItem">
                    <Link to="/vosstats">Vos Stats</Link>
              </li> 
              
          

              
              <li className="globalMenuItem">
                <Link to="/about">À propos</Link>
              </li>

              </ul>
            </nav>
          </div>
            <Routes>
              <Route path="/uploadlpb" element={<UploadLPB />} />

              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <StatsPage />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminPage />
                  </PrivateRoute>
                }
              />            
              <Route path="/statsplatforms" element={<StatsPlatforms />} />
              <Route path="/vosstats" element={<VosStatsPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/leave" element={<LeavePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/article-bien-demarrer" element={<Article1 /> } />
              
            </Routes>
        </div>
      </Router>
    
  );
}

/*export default App;
export { AuthContext as AuthProvider, useAuth };
*/
export { App, AuthProvider, useAuth };
 