import React from 'react';

function LeavePage() {
  return (
    <div>
      <h1>À bientôt</h1>
      <p>et bons investissements ! ;-)</p>
    </div>
  );
}

export default LeavePage;
