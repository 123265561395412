import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./App";
import styles from "./LoginPage.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();
  /*
  const handleSubmit = async (event) => {
    event.preventDefault();
    await auth.login(email, password);
    navigate('/');
  };
*/
/*
  const dummyUser = {
    email: "alex@immozaik.com",
    password: "test1234",
  };
*/
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const redirectTo = new URLSearchParams(location.search).get("redirectTo") || "/";
    
    //if (email === dummyUser.email && password === dummyUser.password) {
      //login(email, password);
      login(email, password).then(() => {
        navigate(redirectTo);
      });
    //} else {
    //  alert('Identifiants incorrects');
   // }
  };
  

  return (
    <div className="loginPage">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Adresse e-mail : </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <br ></br>
        <div className="form-group">
          <label htmlFor="password">Mot de passe : </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <br ></br>
        <button type="submit" className="btn btn-primary">
          Se connecter
        </button>
      </form>
    </div>
  );
}

export default LoginPage;
